import React, { useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { getEndpointUrl, log } from '../../../helpers/Utils'
import {
  DISABLED_CLASS,
  HIDE_DROPDOWN_TIMEOUT,
  IMAGE_TYPES,
  ORDER_STATUSES,
  SELECTED_IMAGES_CLASS,
} from '../../../helpers/Constants'
import { API_ENDPOINTS_GALLERY } from '../../../helpers/Urls'
import { Translation } from '../../../helpers/Translation'

import Tooltip from '../../../components/Tooltip'

import { ReactComponent as ActionBranchSvg } from '../../../svg/action_branch.svg'
import { ReactComponent as ActionCheckSvg } from '../../../svg/action_check.svg'
import { ReactComponent as ActionCommentSvg } from '../../../svg/action_comment.svg'
import { ReactComponent as ActionCrossSvg } from '../../../svg/action_cross.svg'
import { ReactComponent as ActionDislikeSvg } from '../../../svg/action_dislike.svg'
import { ReactComponent as ActionDownloadSvg } from '../../../svg/action_download.svg'
import { ReactComponent as ActionEraserSvg } from '../../../svg/action_eraser.svg'
import { ReactComponent as ActionEuroSvg } from '../../../svg/action_euro.svg'
import { ReactComponent as ActionEyeSvg } from '../../../svg/action_eye.svg'
import { ReactComponent as ActionEyeDropperSvg } from '../../../svg/action_eye_dropper.svg'
import { ReactComponent as ActionLikeSvg } from '../../../svg/action_like.svg'
import { ReactComponent as ActionRefreshSvg } from '../../../svg/action_refresh.svg'
import { ReactComponent as ActionRevertSvg } from '../../../svg/action_revert.svg'
import { ReactComponent as ActionRestoreSvg } from '../../../svg/action_restore.svg'
import { ReactComponent as ActionTestImageSvg } from '../../../svg/action_test_image.svg'
import { ReactComponent as ActionTrashSvg } from '../../../svg/action_trash.svg'
import { ReactComponent as ActionMenuSvg } from '../../../svg/action_menu.svg'
import { ReactComponent as ChevronDownSvg } from '../../../svg/chevron_down.svg'

import './index.scss'

const Actions = ({ refreshGallery, resetInterval }) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateDialogModalOpened: actions.layout.updateDialogModalOpened,
    updateDialogModalState: actions.layout.updateDialogModalState,
    updateDeleteErrorsModalOpened: actions.layout.updateDeleteErrorsModalOpened,
    updateExplodeOrderModalOpened: actions.layout.updateExplodeOrderModalOpened,
    updateUpdatePricesModalOpened: actions.layout.updateUpdatePricesModalOpened,
  }))

  const orderState = useStoreState((state) => ({
    orderId: state.order.orderId,
    selectedImages: state.order.selectedImages,
    filteredImages: state.order.filteredImages,
    imageType: state.order.imageType,
    gallery: state.order.gallery,
  }))

  const orderActions = useStoreActions((actions) => ({
    imageAction: actions.order.imageAction,
    setImagesToBeDeleted: actions.order.setImagesToBeDeleted,
    setCommentsToBeDeleted: actions.order.setCommentsToBeDeleted,
    changeReference: actions.order.changeReference,
    getExplodeOrderList: actions.order.getExplodeOrderList,
    setTestImage: actions.order.setTestImage,
    restoreImages: actions.order.restoreImages,
    setSelectedImages: actions.order.setSelectedImages,
  }))

  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const isMouseOverRef = useRef(false)

  const onConfirmOrRedo = async (status) => {
    const res = await orderActions.imageAction({
      url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.confirm),
      body: {
        image_ids: orderState.selectedImages,
        status,
      },
    })

    if (res.success) {
      refreshGallery()
      resetInterval()
    }
  }

  const onDelete = async () => {
    orderActions.setImagesToBeDeleted(orderState.selectedImages)
    layoutActions.updateDialogModalState(0)
    layoutActions.updateDialogModalOpened(true)
  }

  const onDownload = async () => {
    const selectedList = document.querySelectorAll(`.${SELECTED_IMAGES_CLASS}`)
    const timeoutInit = 1300
    let timeoutIncrease = 0
    selectedList.forEach((item, index) => {
      setTimeout(() => {
        const btn = item.getElementsByClassName('download')[0]
        if (btn) btn.click()
        timeoutIncrease += 10
      }, (timeoutInit + timeoutIncrease) * index)
    })
  }

  const onDeleteComments = async () => {
    orderActions.setCommentsToBeDeleted(orderState.selectedImages)
    layoutActions.updateDialogModalState(1)
    layoutActions.updateDialogModalOpened(true)
  }

  const onAddComment = async () => {
    if (orderState.gallery?.is_allowed_to_comment !== 1) return

    orderActions.setCommentsToBeDeleted(orderState.selectedImages)
    layoutActions.updateDialogModalState(2)
    layoutActions.updateDialogModalOpened(true)
  }

  const onDeleteErrors = async () => {
    layoutActions.updateDeleteErrorsModalOpened(true)
  }

  const onChangeReference = async (referenceType) => {
    const res = await orderActions.changeReference({
      is_admin: userState.user.is_admin,
      body: {
        image_ids: orderState.selectedImages,
        reference_type: referenceType,
      },
    })

    if (res.success) {
      refreshGallery()
      resetInterval()
    }
  }

  const onExplodeOrder = async () => {
    await orderActions.getExplodeOrderList({
      is_admin: userState.user.is_admin,
      orderId: orderState.orderId,
    })

    layoutActions.updateExplodeOrderModalOpened(true)
  }

  const onUpdatePrices = () => {
    layoutActions.updateUpdatePricesModalOpened(true)
  }

  const findFirstMatchId = (array1, array2) => {
    for (let i = 0; i < array1.length; i += 1) {
      const currentId = array1[i].id
      if (array2.includes(currentId)) {
        return currentId
      }
    }
    return null
  }

  const onSelectTestImage = async () => {
    const res = await orderActions.setTestImage({
      is_admin: userState.user.is_admin,
      body: {
        image_id: findFirstMatchId(orderState.filteredImages, orderState.selectedImages),
      },
    })

    if (res) {
      refreshGallery()
      resetInterval()
    }
  }

  const onRestoreImages = async () => {
    const res = await orderActions.restoreImages({
      is_admin: userState.user.is_admin,
      body: {
        image_ids: orderState.selectedImages,
      },
    })

    if (res) {
      refreshGallery()
      resetInterval()
    }
  }

  const onClearSelectedImages = () => {
    orderActions.setSelectedImages([])
  }

  const buttons = [
    {
      label: Translation.explode_order,
      svg: <ActionBranchSvg />,
      isShown: userState.user.is_admin,
      onclick: onExplodeOrder,
    },
    {
      label: Translation.confirm_selected_images,
      svg: <ActionCheckSvg />,
      isShown: userState.user.is_admin && orderState.imageType === IMAGE_TYPES.output,
      onclick: () => onConfirmOrRedo(true),
    },
    {
      label: Translation.not_confirm_selected_images,
      svg: <ActionRevertSvg />,
      isShown: userState.user.is_admin && orderState.imageType === IMAGE_TYPES.output,
      onclick: () => onConfirmOrRedo(false),
    },
    {
      label: Translation.approve_clients_comments_for_selected_images,
      svg: <ActionLikeSvg />,
      isShown: userState.user.is_admin,
      onclick: () => log(Translation.approve_clients_comments_for_selected_images),
    },
    {
      label: Translation.confirm_selected_images,
      svg: <ActionLikeSvg />,
      isShown: !userState.user.is_admin && orderState.imageType === IMAGE_TYPES.output,
      onclick: () => onConfirmOrRedo(true),
    },
    {
      label: Translation.not_confirm_selected_images,
      svg: <ActionDislikeSvg />,
      isShown: !userState.user.is_admin && orderState.imageType === IMAGE_TYPES.output,
      onclick: () => onConfirmOrRedo(false),
    },
    {
      label: orderState.gallery?.is_allowed_to_comment === 1
        ? Translation.add_comment_selected_images : Translation.add_comments_not_allowed,
      svg: <ActionCommentSvg />,
      isShown: (userState.user.is_admin
        || (!userState.user.is_admin && orderState.gallery?.order_status_id === ORDER_STATUSES.client_qa_input)),
      onclick: onAddComment,
      disabled: orderState.gallery?.is_allowed_to_comment !== 1,
    },
    {
      label: Translation.delete_comments_for_selected_images,
      svg: <ActionCrossSvg />,
      isShown: (userState.user.is_admin
        || (!userState.user.is_admin && orderState.gallery?.order_status_id === ORDER_STATUSES.client_qa_input)),
      onclick: onDeleteComments,
    },
    {
      label: Translation.download_selected_images,
      svg: <ActionDownloadSvg />,
      isShown: true,
      onclick: onDownload,
    },
    {
      label: Translation.delete_errors_for_selected_images,
      svg: <ActionEraserSvg />,
      isShown: userState.user.is_admin,
      onclick: onDeleteErrors,
    },
    {
      label: Translation.update_prices_for_selected_images,
      svg: <ActionEuroSvg />,
      isShown: userState.user.is_admin && orderState.imageType === IMAGE_TYPES.input,
      onclick: onUpdatePrices,
    },
    {
      label: Translation.mark_selected_images_as_internal_reference,
      svg: <ActionEyeSvg />,
      isShown: userState.user.is_admin,
      onclick: () => onChangeReference('internal_reference'),
    },
    {
      label: Translation.mark_selected_images_as_colouration_reference,
      svg: <ActionEyeDropperSvg />,
      isShown: userState.user.is_admin,
      onclick: () => onChangeReference('coloration_reference'),
      // onclick: () => setIsMenuOpened(!isMenuOpened),
      /* menuItems: [
        {
          label: 'eye',
          svg: <ActionEyeSvg />,
          onclick: () => log(Translation.mark_selected_images_as_internal_reference),
        },
        {
          label: 'eye_dropper',
          svg: <ActionEyeDropperSvg />,
          onclick: () => log(Translation.mark_selected_images_as_colouration_reference),
        },
      ], */
      isMenuOpened: false,
      onMouseEnter: () => {
        isMouseOverRef.current = true
      },
      onMouseLeave: () => {
        isMouseOverRef.current = false

        setTimeout(() => {
          if (isMenuOpened && !isMouseOverRef.current) {
            setIsMenuOpened(false)
          }
        }, HIDE_DROPDOWN_TIMEOUT)
      },
    },
    {
      label: Translation.refresh_selected_images,
      svg: <ActionRefreshSvg />,
      isShown: true,
      onclick: () => {
        refreshGallery()
        resetInterval()
      },
    },
    {
      label: Translation.mark_as_test_selected_image,
      svg: <ActionTestImageSvg />,
      isShown: userState.user.is_admin && orderState.imageType === IMAGE_TYPES.input,
      onclick: onSelectTestImage,
    },
    {
      label: Translation.restore_selected_images,
      svg: <ActionRestoreSvg />,
      isShown: userState.user.is_admin,
      onclick: onRestoreImages,
    },
    {
      label: Translation.delete_selected_images,
      svg: <ActionTrashSvg />,
      isShown: userState.user.is_admin,
      onclick: onDelete,
    },
    {
      label: Translation.show_more_selected_images,
      svg: <ActionMenuSvg />,
      isShown: userState.user.is_admin,
      onclick: () => log(Translation.show_more_selected_images),
    },
  ]

  return (
    <div className="actions">
      <Tooltip
        text={Translation.unselect_all}
      >
        <div className="selected-count" onClick={onClearSelectedImages}>
          {orderState.selectedImages.length}
        </div>
      </Tooltip>

      <div className="separator" />

      {buttons.map((button, index) => (button.isShown && (
        <Tooltip
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          text={button.label}
        >
          <button
            type="button"
            label={button.label}
            className={`actions__button ${button.disabled && DISABLED_CLASS}`}
            onClick={button.onclick}
            onMouseLeave={button.onMouseLeave}
            onMouseEnter={button.onMouseEnter}
          >
            {button.svg}
            {button.menuItems && <ChevronDownSvg className="button-menu-arrow" />}
            {isMenuOpened && button.menuItems && (
              <div className="button-menu">
                {button.menuItems?.map((menuItem, menuItemIndex) => (
                  <button
                    // eslint-disable-next-line react/no-array-index-key
                    key={menuItemIndex}
                    type="button"
                    label="action"
                    onClick={menuItem.onclick}
                    className="actions__button"
                  >
                    {menuItem.svg}
                  </button>
                ))}
              </div>
            )}
          </button>
        </Tooltip>
      )))}
    </div>
  )
}

Actions.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Actions.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default Actions
