import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'
import { useEventListener } from '../../../../helpers/Utils'

import Dialog from '../../../../components/Dialog'
import RadioGroup from '../../../../components/RadioGroup'

const ExplodeOrderDialog = ({ refreshGallery, resetInterval }) => {
  const layoutState = useStoreState((state) => ({
    isExplodeOrderModalOpened: state.layout.isExplodeOrderModalOpened,
  }))

  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    selectedImages: state.order.selectedImages,
    explodeOrderList: state.order.explodeOrderList,
    orderId: state.order.orderId,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateExplodeOrderModalOpened: actions.layout.updateExplodeOrderModalOpened,
  }))

  const orderActions = useStoreActions((actions) => ({
    explodeOrder: actions.order.explodeOrder,
  }))

  const [selectedToOrderId, setSelectedToOrderId] = useState('0')

  const onExplodeOrder = async () => {
    if (selectedToOrderId) {
      const res = await orderActions.explodeOrder({
        is_admin: userState.user.is_admin,
        body: {
          image_ids: orderState.selectedImages,
          from_order_id: orderState.orderId,
          to_order_id: parseInt(selectedToOrderId, 10),
        },
      })

      if (res) {
        refreshGallery()
        resetInterval()
        layoutActions.updateExplodeOrderModalOpened(false)
      }
    }
  }

  useEventListener('keyup', (e) => {
    if (e.key === 'Enter' && layoutState.isExplodeOrderModalOpened) {
      onExplodeOrder().then(() => {
      })
    }
  })

  return (
    <Dialog
      isShown
      onClickCloseIcon={() => layoutActions.updateExplodeOrderModalOpened(false)}
      onClickOutside={() => layoutActions.updateExplodeOrderModalOpened(false)}
      isCentered
      onClickCancel={() => layoutActions.updateExplodeOrderModalOpened(false)}
      title={Translation.explode_order}
      yesLabel={Translation.ok}
      cancelLabel={Translation.cancel}
      onClickYes={onExplodeOrder}
      content={(
        <RadioGroup
          values={orderState.explodeOrderList}
          onChange={(e) => setSelectedToOrderId(e.target.value)}
          value={selectedToOrderId}
        />
      )}
    />
  )
}

ExplodeOrderDialog.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

ExplodeOrderDialog.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default ExplodeOrderDialog
