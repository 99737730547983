import React from 'react'
import PropTypes from 'prop-types'

import { COMMENT_STATUSES, COMMENT_TYPES } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'
import { prepareComment } from '../../../../helpers/Utils'

import { ReactComponent as CommentedDrawingFreehandSvg } from '../../../../svg/commented_drawing_freehand.svg'

import './index.scss'

const HistoryOverlay = ({ comment }) => (
  <div className="overlay--comment__wrap">
    <div className="overlay--comment__icon overlay--comment__type">
      <CommentedDrawingFreehandSvg />
    </div>

    {(comment.type === COMMENT_TYPES.qaClient) && (
      <div className={`overlay--comment__icon overlay--comment__icon--client 
        ${parseInt(comment.status.toString(), 10) === COMMENT_STATUSES.active
        ? 'overlay--comment__icon--client__approved' : ''}`}
      >
        {Translation.client_short}
      </div>
    )}

    <div className="overlay--comment">
      {prepareComment(comment.comment)}
    </div>
  </div>
)

HistoryOverlay.propTypes = {
  comment: PropTypes.instanceOf(Object),
}

HistoryOverlay.defaultProps = {
  comment: {},
}

export default HistoryOverlay
