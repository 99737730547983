import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../../helpers/Translation'
import {
  ACTIVE_CLASS,
  DEFAULT_DRAWING_TYPE,
  DRAWINGS_COLORS,
  STROKE_WIDTH_RATIO,
} from '../../../../helpers/Constants'
import { parseFloatRound, useEventListener } from '../../../../helpers/Utils'

import ColorPicker from '../../../../components/ColorPicker'

import { ReactComponent as CommentIconSvg } from '../../../../svg/t_logo.svg'
import { ReactComponent as CommentDrawingOvalSvg } from '../../../../svg/comment_drawing_oval.svg'
import { ReactComponent as CommentDrawingRectangleSvg } from '../../../../svg/comment_drawing_rectangle.svg'
import { ReactComponent as CommentDrawingArrowSvg } from '../../../../svg/comment_drawing_arrow.svg'
import { ReactComponent as CommentDrawingFreehandSvg } from '../../../../svg/comment_drawing_freehand.svg'
import { ReactComponent as CommentDrawingLineSvg } from '../../../../svg/comment_drawing_line.svg'
import { ReactComponent as EnterIconSvg } from '../../../../svg/enter.svg'

import './index.scss'

const DRAW_TYPES = [
  {
    type: DEFAULT_DRAWING_TYPE,
    icon: <CommentDrawingOvalSvg />,
  },
  {
    type: 'rectangular',
    icon: <CommentDrawingRectangleSvg />,
  },
  {
    type: 'arrow',
    icon: <CommentDrawingArrowSvg />,
  },
  {
    type: 'freehand',
    icon: <CommentDrawingFreehandSvg />,
  },
  {
    type: 'line',
    icon: <CommentDrawingLineSvg />,
  },
]

const Comment = ({
  refreshGallery,
  resetInterval,
  overlayImgDrawingRef,
  resizeRatioRef,
  isSpaceRequired,
}) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    images: state.order.images,
    overlayImgIndex: state.order.overlayImgIndex,
    gallery: state.order.gallery,
    drawingColor: state.order.drawingColor,
    drawingType: state.order.drawingType,
  }))

  const orderActions = useStoreActions((actions) => ({
    addComment: actions.order.addComment,
    setDrawingColor: actions.order.setDrawingColor,
    setDrawingType: actions.order.setDrawingType,
  }))

  const [comment, setComment] = useState('')
  const [openColorPickerComment, setOpenColorPickerComment] = useState(false)

  const drawingType = useRef(orderState.drawingType)
  const imageSizes = useRef({ width: 0, height: 0 })
  const strokeColor = useRef(orderState.drawingColor)
  const strokeWidth = useRef(null)
  const isDrawing = useRef(false)
  const startX = useRef(0)
  const startY = useRef(0)
  const currentShape = useRef(null)
  const shapesJson = useRef([])
  const isSpaceRequiredRef = useRef(isSpaceRequired)
  const isSpacePressedRef = useRef(false)

  const commentTextareaRef = useRef(null)

  const handleAddComment = async () => {
    if (orderState.gallery?.is_allowed_to_comment !== 1) return
    if (!comment) return

    const res = await orderActions.addComment({
      is_admin: userState.user.is_admin,
      body: {
        image_ids: [orderState.images[orderState.overlayImgIndex].id],
        comment,
        is_reply: false,
        shapes: shapesJson.current,
      },
    })

    if (res) {
      refreshGallery()
      resetInterval()
      setComment('')
    }
  }

  const onCommentSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddComment().then(() => {
      })
    }
  }

  const handleCommentLogoClick = () => {
    commentTextareaRef.current.focus()
  }

  const handleCommentColorSelect = (color) => {
    orderActions.setDrawingColor(color)
    setOpenColorPickerComment(false)
  }

  const closePicker = () => {
    setOpenColorPickerComment(false)
  }

  const getEndCoordinates = (e) => {
    const rect = overlayImgDrawingRef.current.getBoundingClientRect()
    let endX = (e.clientX - rect.left) / resizeRatioRef.current
    let endY = (e.clientY - rect.top) / resizeRatioRef.current

    // Check if Shift key is pressed for specific shapes
    if (e.shiftKey) {
      if (['line', 'arrow'].includes(drawingType.current)) {
        // vertical or horizontal
        if (Math.abs(endX - startX.current) > Math.abs(endY - startY.current)) {
          endY = startY.current
        } else {
          endX = startX.current
        }
      } else if (drawingType.current === DEFAULT_DRAWING_TYPE) {
        // circle
        const radius = Math.min(Math.abs(endX - startX.current), Math.abs(endY - startY.current)) / 2
        endX = startX.current + (endX > startX.current ? radius * 2 : -radius * 2)
        endY = startY.current + (endY > startY.current ? radius * 2 : -radius * 2)
      } else if (drawingType.current === 'rectangular') {
        // square
        const size = Math.min(
          Math.abs(endX - startX.current),
          Math.abs(endY - startY.current),
        )
        endX = startX.current + (endX > startX.current ? size : -size)
        endY = startY.current + (endY > startY.current ? size : -size)
      }
    }

    // mouse should not go outside image
    endX = parseFloatRound(Math.max(0, Math.min(endX, imageSizes.current.width)))
    endY = parseFloatRound(Math.max(0, Math.min(endY, imageSizes.current.height)))

    return { endX, endY }
  }

  const createArrowLine = (x1, y1, x2, y2, stroke) => {
    const line = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'line',
    )
    line.setAttribute('x1', x1)
    line.setAttribute('y1', y1)
    line.setAttribute('x2', x2)
    line.setAttribute('y2', y2)
    line.setAttribute('stroke', stroke)
    line.setAttribute('stroke-width', strokeWidth.current)
    return line
  }

  const drawArrowhead = (x1, y1, x2, y2, stroke) => {
    const arrowLength = 8 * strokeWidth.current
    const arrowAngle = Math.atan2(y2 - y1, x2 - x1)
    const arrowPoint1 = {
      x: x2 - arrowLength * Math.cos(arrowAngle - Math.PI / 6),
      y: y2 - arrowLength * Math.sin(arrowAngle - Math.PI / 6),
    }
    const arrowPoint2 = {
      x: x2 - arrowLength * Math.cos(arrowAngle + Math.PI / 6),
      y: y2 - arrowLength * Math.sin(arrowAngle + Math.PI / 6),
    }

    return [
      createArrowLine(x2, y2, arrowPoint1.x, arrowPoint1.y, stroke),
      createArrowLine(x2, y2, arrowPoint2.x, arrowPoint2.y, stroke),
    ]
  }

  const createShape = () => {
    switch (drawingType.current) {
      case 'line':
      case 'arrow':
        currentShape.current = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'line',
        )
        currentShape.current.setAttribute('x1', startX.current)
        currentShape.current.setAttribute('y1', startY.current)
        currentShape.current.setAttribute('x2', startX.current)
        currentShape.current.setAttribute('y2', startY.current)
        currentShape.current.setAttribute('stroke', strokeColor.current)
        currentShape.current.setAttribute('stroke-width', strokeWidth.current)
        break
      case DEFAULT_DRAWING_TYPE:
        currentShape.current = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'ellipse',
        )
        currentShape.current.setAttribute('cx', startX.current)
        currentShape.current.setAttribute('cy', startY.current)
        currentShape.current.setAttribute('rx', '0')
        currentShape.current.setAttribute('ry', '0')
        currentShape.current.setAttribute('stroke', strokeColor.current)
        currentShape.current.setAttribute('stroke-width', strokeWidth.current)
        currentShape.current.setAttribute('fill', 'none')
        break
      case 'rectangular':
        currentShape.current = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'rect',
        )
        currentShape.current.setAttribute('x', startX.current)
        currentShape.current.setAttribute('y', startY.current)
        currentShape.current.setAttribute('width', '0')
        currentShape.current.setAttribute('height', '0')
        currentShape.current.setAttribute('stroke', strokeColor.current)
        currentShape.current.setAttribute('stroke-width', strokeWidth.current)
        currentShape.current.setAttribute('fill', 'none')
        break
      case 'freehand':
        currentShape.current = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path',
        )
        currentShape.current.setAttribute(
          'd',
          `M ${startX.current} ${startY.current}`,
        )
        currentShape.current.setAttribute('stroke', strokeColor.current)
        currentShape.current.setAttribute('stroke-width', strokeWidth.current)
        currentShape.current.setAttribute('fill', 'none')
        break
      default:
        break
    }
  }

  const startDrawing = (e) => {
    if (drawingType.current === null || !isSpaceRequiredRef.current || isSpacePressedRef.current) return

    isDrawing.current = true

    const rect = overlayImgDrawingRef.current.getBoundingClientRect()
    startX.current = parseFloatRound((e.clientX - rect.left) / resizeRatioRef.current)
    startY.current = parseFloatRound((e.clientY - rect.top) / resizeRatioRef.current)

    // mouse should go outside image
    if (startX.current < 0) startX.current = 0
    else if (startX.current > imageSizes.current.width) startX.current = imageSizes.current.width
    if (startY.current < 0) startY.current = 0
    else if (startY.current > imageSizes.current.height) startY.current = imageSizes.current.height

    createShape()
    overlayImgDrawingRef.current.appendChild(currentShape.current)
  }

  const drawShape = (e) => {
    if (!isDrawing.current || !currentShape.current) return

    const { endX, endY } = getEndCoordinates(
      e,
      startX.current,
      startY.current,
    )

    switch (drawingType.current) {
      case 'line':
      case 'arrow':
        currentShape.current.setAttribute('x2', endX)
        currentShape.current.setAttribute('y2', endY)
        break
      case DEFAULT_DRAWING_TYPE:
        // eslint-disable-next-line no-case-declarations
        const radiusX = parseFloatRound(Math.abs(endX - startX.current) / 2)
        // eslint-disable-next-line no-case-declarations
        const radiusY = parseFloatRound(Math.abs(endY - startY.current) / 2)
        currentShape.current.setAttribute('rx', radiusX)
        currentShape.current.setAttribute('ry', radiusY)
        currentShape.current.setAttribute(
          'cx',
          parseFloatRound(
            startX.current + (endX > startX.current ? radiusX : -radiusX),
          ),
        )
        currentShape.current.setAttribute(
          'cy',
          parseFloatRound(
            startY.current + (endY > startY.current ? radiusY : -radiusY),
          ),
        )
        break
      case 'rectangular':
        // eslint-disable-next-line no-case-declarations
        const width = parseFloatRound(endX - startX.current)
        // eslint-disable-next-line no-case-declarations
        const height = parseFloatRound(endY - startY.current)
        currentShape.current.setAttribute('width', Math.abs(width))
        currentShape.current.setAttribute('height', Math.abs(height))
        currentShape.current.setAttribute(
          'x',
          width < 0 ? endX : startX.current,
        )
        currentShape.current.setAttribute(
          'y',
          height < 0 ? endY : startY.current,
        )
        break
      case 'freehand':
        // eslint-disable-next-line no-case-declarations
        const d = currentShape.current.getAttribute('d')
        currentShape.current.setAttribute('d', `${d} L ${endX} ${endY}`)
        break
      default:
        break
    }
  }

  const endDrawing = () => {
    if (!isDrawing.current) return

    const shapeData = {
      type: drawingType.current,
      stroke: strokeColor.current,
    }

    switch (drawingType.current) {
      case 'line':
      case 'arrow':
        shapeData.x1 = startX.current
        shapeData.y1 = startY.current
        shapeData.x2 = parseFloat(currentShape.current.getAttribute('x2'))
        shapeData.y2 = parseFloat(currentShape.current.getAttribute('y2'))
        if (drawingType.current === 'arrow') {
          const [arrowHeadLine1, arrowHeadLine2] = drawArrowhead(
            shapeData.x1,
            shapeData.y1,
            shapeData.x2,
            shapeData.y2,
            shapeData.stroke,
          )
          overlayImgDrawingRef.current.appendChild(arrowHeadLine1)
          overlayImgDrawingRef.current.appendChild(arrowHeadLine2)
        }
        break
      case DEFAULT_DRAWING_TYPE:
        shapeData.cx = parseFloat(currentShape.current.getAttribute('cx'))
        shapeData.cy = parseFloat(currentShape.current.getAttribute('cy'))
        shapeData.rx = parseFloat(currentShape.current.getAttribute('rx'))
        shapeData.ry = parseFloat(currentShape.current.getAttribute('ry'))
        break
      case 'rectangular':
        shapeData.x = parseFloat(currentShape.current.getAttribute('x'))
        shapeData.y = parseFloat(currentShape.current.getAttribute('y'))
        shapeData.width = parseFloat(
          currentShape.current.getAttribute('width'),
        )
        shapeData.height = parseFloat(
          currentShape.current.getAttribute('height'),
        )
        break
      case 'freehand':
        shapeData.d = currentShape.current.getAttribute('d')
        break
      default:
        break
    }

    shapesJson.current.push(shapeData)
    currentShape.current = null
    isDrawing.current = false
  }

  const redrawShapes = () => {
    // eslint-disable-next-line no-param-reassign
    overlayImgDrawingRef.current.innerHTML = ''

    shapesJson.current.forEach((shapeData) => {
      let shapeElement

      switch (shapeData.type) {
        case 'line':
        case 'arrow':
          shapeElement = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'line',
          )
          shapeElement.setAttribute('x1', shapeData.x1)
          shapeElement.setAttribute('y1', shapeData.y1)
          shapeElement.setAttribute('x2', shapeData.x2)
          shapeElement.setAttribute('y2', shapeData.y2)
          shapeElement.setAttribute('stroke', shapeData.stroke)
          shapeElement.setAttribute('stroke-width', strokeWidth.current)
          if (shapeData.type === 'arrow') {
            const [arrowHeadLine1, arrowHeadLine2] = drawArrowhead(
              shapeData.x1,
              shapeData.y1,
              shapeData.x2,
              shapeData.y2,
              shapeData.stroke,
            )
            overlayImgDrawingRef.current.appendChild(arrowHeadLine1)
            overlayImgDrawingRef.current.appendChild(arrowHeadLine2)
          }
          break
        case DEFAULT_DRAWING_TYPE:
          shapeElement = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'ellipse',
          )
          shapeElement.setAttribute('cx', shapeData.cx)
          shapeElement.setAttribute('cy', shapeData.cy)
          shapeElement.setAttribute('rx', shapeData.rx)
          shapeElement.setAttribute('ry', shapeData.ry)
          shapeElement.setAttribute('stroke', shapeData.stroke)
          shapeElement.setAttribute('stroke-width', strokeWidth.current)
          shapeElement.setAttribute('fill', 'none')
          break
        case 'rectangular':
          shapeElement = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'rect',
          )
          shapeElement.setAttribute('x', shapeData.x)
          shapeElement.setAttribute('y', shapeData.y)
          shapeElement.setAttribute('width', shapeData.width)
          shapeElement.setAttribute('height', shapeData.height)
          shapeElement.setAttribute('stroke', shapeData.stroke)
          shapeElement.setAttribute('stroke-width', strokeWidth.current)
          shapeElement.setAttribute('fill', 'none')
          break
        case 'freehand':
          shapeElement = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'path',
          )
          shapeElement.setAttribute('d', shapeData.d)
          shapeElement.setAttribute('stroke', shapeData.stroke)
          shapeElement.setAttribute('stroke-width', strokeWidth.current)
          shapeElement.setAttribute('fill', 'none')
          break
        default:
          break
      }

      overlayImgDrawingRef.current.appendChild(shapeElement)
    })
  }

  const undoLastShape = () => {
    if (shapesJson.current.length === 0) return

    shapesJson.current.pop()
    redrawShapes()
  }

  const updateStrokeWidth = () => {
    redrawShapes()
  }

  const handleDrawTypeClick = (e) => {
    if (e.type === drawingType.current) {
      drawingType.current = null
      orderActions.setDrawingType(null)
      return
    }
    drawingType.current = e.type
    orderActions.setDrawingType(e.type)
  }

  useEventListener('keydown', (e) => {
    if (e.key === 'z' && e.ctrlKey) {
      undoLastShape()
    }
  })

  useEventListener('keydown', (e) => {
    if (e.code === 'Space') {
      isSpacePressedRef.current = true
    }
  })

  useEventListener('keyup', (e) => {
    if (e.code === 'Space') {
      isSpacePressedRef.current = false
    }
  })

  useEffect(() => {
    if (overlayImgDrawingRef.current) {
      overlayImgDrawingRef.current.addEventListener('mousedown', startDrawing)
      overlayImgDrawingRef.current.addEventListener('mousemove', drawShape)
      overlayImgDrawingRef.current.addEventListener('mouseup', endDrawing)
    }

    window.addEventListener('resize', updateStrokeWidth)

    return () => {
      window.removeEventListener('resize', updateStrokeWidth)
    }
  }, [])

  useEffect(() => {
    imageSizes.current = {
      width: orderState.images[orderState.overlayImgIndex].width,
      height: orderState.images[orderState.overlayImgIndex].height,
    }

    const maxDimension = Math.max(
      overlayImgDrawingRef.current.getAttribute('width'),
      overlayImgDrawingRef.current.getAttribute('height'),
    )

    strokeWidth.current = parseFloatRound(maxDimension / STROKE_WIDTH_RATIO)
    // console.log('drawing strokeWidth', strokeWidth.current)

    shapesJson.current = []
    redrawShapes()
  }, [orderState.overlayImgIndex])

  useEffect(() => {
    drawingType.current = orderState.drawingType
  }, [orderState.drawingType])

  useEffect(() => {
    strokeColor.current = orderState.drawingColor
  }, [orderState.drawingColor])

  useEffect(() => {
    isSpaceRequiredRef.current = isSpaceRequired
  }, [isSpaceRequired])

  return (
    <div className="comment-section">
      <textarea
        placeholder={`${Translation.leave_your_comment}...`}
        className="comment-textarea"
        ref={commentTextareaRef}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        onKeyDown={onCommentSubmit}
        {...(comment && { style: { textIndent: 0 } })}
      />

      {!comment && (
        <CommentIconSvg className="comment-icon" onClick={handleCommentLogoClick} />
      )}

      <div className="comment-section__bottom">
        <div className="comment-section__styles">
          <div className="comment-section__color" style={{ display: 'flex' }}>
            {openColorPickerComment && (
              <ColorPicker
                onColorChange={handleCommentColorSelect}
                closePicker={closePicker}
                colors={DRAWINGS_COLORS}
              />
            )}
            <button
              type="button"
              label="style"
              className="comment-section__style-button"
              style={{ backgroundColor: orderState.drawingColor }}
              onClick={() => setOpenColorPickerComment(true)}
            />
          </div>
          {DRAW_TYPES.map((type) => (
            <button
              key={type.type}
              type="button"
              label="style"
              className={`comment-section__style-button ${orderState.drawingType === type.type && ACTIVE_CLASS}`}
              onClick={() => handleDrawTypeClick(type)}
            >
              {type.icon}
            </button>
          ))}
        </div>
        <button
          className="comment-section__button"
          type="button"
          onClick={handleAddComment}
        >
          {Translation.add_comment}
          <EnterIconSvg />
        </button>
      </div>
    </div>
  )
}

Comment.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  overlayImgDrawingRef: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  resizeRatioRef: PropTypes.object,
  isSpaceRequired: PropTypes.bool,
}

Comment.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
  overlayImgDrawingRef: {},
  resizeRatioRef: {},
  isSpaceRequired: false,
}

export default Comment
