import React from 'react'
import PropTypes from 'prop-types'

import { redirectToMyDoopic } from '../../helpers/Utils'
import { PX_TO_REM } from '../../helpers/Constants'

import Typography from '../../components/Typography'

import { ReactComponent as LogoSvg } from '../../svg/logo.svg'

import './index.scss'

const Error = ({
  errorType, errorTitle, errorDescr, btnLabel,
}) => (
  <div className="error-page">
    <div className="header-logo--wrap">
      <div className="header-logo">
        <div
          onClick={redirectToMyDoopic}
          id="logo-link"
        >
          <LogoSvg className="header-logo--icon" />
        </div>
      </div>
    </div>

    <div className="error--wrap">
      <div className="screen-center">
        <div className="width-100vw">
          {errorType && (
          <div className="error-type">
            <Typography
              font="semibold"
              label={errorType}
              fontSize={PX_TO_REM['190']}
              lineHeight={PX_TO_REM['34']}
            />
          </div>
          )}

          <div className="m-top-24 error-title">
            <Typography
              variant="h2"
              font="medium"
              label={errorTitle}
              lineHeight={PX_TO_REM['72']}
            />
          </div>

          <div className="m-top-24 error-descr">
            <Typography
              variant="h6"
              label={errorDescr}
              lineHeight={PX_TO_REM['25']}
            />
          </div>

          <div className="m-top-24">
            <button
              className="button-take-home"
              label={btnLabel}
              type="button"
              onClick={redirectToMyDoopic}
            >
              {btnLabel}
            </button>
          </div>
        </div>
      </div>

      <div className="m-top-auto">
        {/* <ErrorFooter isNewTab /> */}
      </div>
    </div>
  </div>
)

Error.propTypes = {
  errorType: PropTypes.string,
  errorTitle: PropTypes.string,
  errorDescr: PropTypes.string,
  btnLabel: PropTypes.string,
}

Error.defaultProps = {
  errorType: '',
  errorTitle: '',
  errorDescr: '',
  btnLabel: '',
}

export default Error
