import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { IMAGE_ERROR_CODES } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'
import { useEventListener } from '../../../../helpers/Utils'

import Dialog from '../../../../components/Dialog'
import Typography from '../../../../components/Typography'
import Checkbox from '../../../../components/Checkbox'

import './index.scss'

const DeleteErrorsDialog = ({ refreshGallery, resetInterval }) => {
  const layoutState = useStoreState((state) => ({
    isDeleteErrorsModalOpened: state.layout.isDeleteErrorsModalOpened,
  }))

  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    selectedImages: state.order.selectedImages,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateDeleteErrorsModalOpened: actions.layout.updateDeleteErrorsModalOpened,
  }))

  const orderActions = useStoreActions((actions) => ({
    removeError: actions.order.removeError,
  }))

  const [errors, setErrors] = useState({
    filename: false,
    format: false,
    width: false,
    height: false,
    dpi: false,
    colourSpace: false,
    background: false,
    colourSampler: false,
    paths: false,
    layers: false,
    alphaChannels: false,
    photoshopDescription: false,
  })

  const onDeleteErrors = async () => {
    if (Object.keys(errors).every((key) => !errors[key])) {
      return
    }

    const res = await orderActions.removeError({
      is_admin: userState.user.is_admin,
      body: {
        image_ids: orderState.selectedImages,
        error_codes: [
          ...errors.filename ? [IMAGE_ERROR_CODES.wrongFileName] : [],
          ...errors.format ? [IMAGE_ERROR_CODES.wrongFormat] : [],
          ...errors.width ? [IMAGE_ERROR_CODES.widthError] : [],
          ...errors.height ? [IMAGE_ERROR_CODES.heightError] : [],
          ...errors.dpi ? [IMAGE_ERROR_CODES.dpiError] : [],
          ...errors.colourSpace ? [IMAGE_ERROR_CODES.colorSpaceError] : [],
          ...errors.background ? [IMAGE_ERROR_CODES.noBgColor] : [],
          ...errors.colourSampler ? [IMAGE_ERROR_CODES.wrongColorSampler] : [],
          ...errors.paths ? [IMAGE_ERROR_CODES.pathError] : [],
          ...errors.layers ? [IMAGE_ERROR_CODES.layerError] : [],
          ...errors.alphaChannels ? [IMAGE_ERROR_CODES.containsAlphaChanel] : [],
          ...errors.photoshopDescription ? [IMAGE_ERROR_CODES.metadataPhotoshopDescriptionError] : [],
        ],
      },
    })

    if (res) {
      refreshGallery()
      resetInterval()
      layoutActions.updateDeleteErrorsModalOpened(false)
    }
  }

  useEventListener('keyup', (e) => {
    if (e.key === 'Enter' && layoutState.isDeleteErrorsModalOpened) {
      onDeleteErrors().then(() => {
      })
    }
  })

  return (
    <Dialog
      isShown
      onClickCloseIcon={() => layoutActions.updateDeleteErrorsModalOpened(false)}
      onClickOutside={() => layoutActions.updateDeleteErrorsModalOpened(false)}
      isCentered
      onClickCancel={() => layoutActions.updateDeleteErrorsModalOpened(false)}
      title={`${Translation.attention}!`}
      yesLabel={Translation.yes}
      cancelLabel={Translation.no}
      onClickYes={onDeleteErrors}
      size="medium"
      disableFullWidthButtons
      content={(
        <>
          <Typography
            theme="lighter"
            variant="xs"
            label={Translation.sure_to_delete_selected_errors}
          />
          <div className="checks-container">
            <div className="check-item filename">
              <Checkbox
                label={Translation.filename}
                id="filename"
                checked={errors.filename}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    filename: !errors.filename,
                  }))
                }}
              />
            </div>
            <div className="check-item format">
              <Checkbox
                label={Translation.format}
                id="format"
                checked={errors.format}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    format: !errors.format,
                  }))
                }}
              />
            </div>
            <div className="divider" />

            <div className="check-item width">
              <Checkbox
                label={Translation.width}
                id="width"
                checked={errors.width}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    width: !errors.width,
                  }))
                }}
              />
            </div>
            <div className="check-item">
              <Checkbox
                label={Translation.height}
                id="height"
                checked={errors.height}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    height: !errors.height,
                  }))
                }}
              />
            </div>
            <div className="check-item">
              <Checkbox
                label={Translation.dpi.toUpperCase()}
                id="dpi"
                checked={errors.dpi}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    dpi: !errors.dpi,
                  }))
                }}
              />
            </div>
            <div className="divider" />

            <div className="check-item colour-space">
              <Checkbox
                label={Translation.colour_space}
                id="colourSpace"
                checked={errors.colourSpace}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    colourSpace: !errors.colourSpace,
                  }))
                }}
              />
            </div>
            <div className="divider" />

            <div className="check-item background">
              <Checkbox
                label={Translation.background}
                id="background"
                checked={errors.background}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    background: !errors.background,
                  }))
                }}
              />
            </div>
            <div className="divider" />

            <div className="check-item colour-sampler">
              <Checkbox
                label={Translation.colour_sampler}
                id="colourSampler"
                checked={errors.colourSampler}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    colourSampler: !errors.colourSampler,
                  }))
                }}
              />
            </div>
            <div className="divider" />

            <div className="check-item">
              <Checkbox
                label={Translation.paths}
                id="paths"
                checked={errors.paths}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    paths: !errors.paths,
                  }))
                }}
              />
            </div>
            <div className="check-item">
              <Checkbox
                label={Translation.layers}
                id="layers"
                checked={errors.layers}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    layers: !errors.layers,
                  }))
                }}
              />
            </div>
            <div className="check-item">
              <Checkbox
                label={Translation.alpha_channels}
                id="alphaChannels"
                checked={errors.alphaChannels}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    alphaChannels: !errors.alphaChannels,
                  }))
                }}
              />
            </div>
            <div className="divider" />

            <div className="check-item">
              <Checkbox
                label={Translation.photoshop_description}
                id="photoshopDescription"
                checked={errors.photoshopDescription}
                size="l"
                onChange={() => {
                  setErrors(() => ({
                    ...errors,
                    photoshopDescription: !errors.photoshopDescription,
                  }))
                }}
              />
            </div>
          </div>
        </>
      )}
    />
  )
}

DeleteErrorsDialog.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

DeleteErrorsDialog.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default DeleteErrorsDialog
