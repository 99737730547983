import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { CompactPicker } from 'react-color'

import './index.scss'

const ColorPicker = ({
  onColorChange,
  closePicker,
  dark,
  colors,
}) => {
  const colorPickerRef = useRef()

  const handleClickOutside = (event) => {
    if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
      closePicker()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={colorPickerRef} className="color-picker">
      <CompactPicker
        onChangeComplete={(c) => onColorChange(c.hex)}
        colors={colors}
        {...(dark && { className: 'color-picker__picker-dark' })}
      />
    </div>
  )
}

ColorPicker.propTypes = {
  onColorChange: PropTypes.func,
  closePicker: PropTypes.func,
  dark: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
}

ColorPicker.defaultProps = {
  onColorChange: () => {
  },
  closePicker: () => {
  },
  dark: true,
}

export default ColorPicker
