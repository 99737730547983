import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'
import {
  brokenFilePreview,
  notFoundPreview,
  addClass,
  debounce,
  dragElement,
  isAltKey,
  parseFloatRound,
  removeClass,
  useEventListener,
  buildGrids,
  buildPath,
  updateVerticalGridLine,
  updateHorizontalGridLine,
  updateGrid,
  getGridColor,
} from '../../../helpers/Utils'
import {
  DEFAULT_IMAGE_SIZES,
  EMPTY_IMG,
  IMAGE_ERROR_CLASS,
  IMAGE_ERROR_CODES,
  IMAGE_SIZES,
  IMAGE_STATUSES,
  IMAGE_TYPES,
  PATH_COLORS,
  PATH_WIDTH_MIN,
  PATH_WIDTH_MAX,
  PX_TO_REM,
  SKELETON_CLASS,
  TRANSPARENT_BG,
  VERTICAL_GRID_CLASS,
  HORIZONTAL_GRID_CLASS,
  SHOW_PATH_CLASS,
  OVERLAY_IMG_CLASS,
  OVERLAY_GRID_CLASS,
  NOSELECT_CLASS,
  ACTIVE_CLASS,
  PATH_DISPLAY_RATIO,
  ALL_LAYERS_KEY,
  DISABLED_CLASS,
  INFO_ERROR_CLASS,
  ZOOM_SPEED,
  SCALE_TIMES,
  IMAGE_OVERLAY_BORDER,
  OVERLAY_GUIDE_CLASS,
  OVERLAY_WRAP_CLASS,
  OVERLAY_DRAWING_CLASS,
  OVERLAY_UNDERLAY_CLASS,
  OVERLAY_CONTRAST_CLASS,
  TRANSPARENT_KEY,
  DEFAULT_DRAWING_TYPE,
} from '../../../helpers/Constants'

import Typography from '../../../components/Typography'
import Range from '../../../components/Range'
import Checkbox from '../../../components/Checkbox'
import Toggle from '../../../components/Toggle'
import RadioGroup from '../../../components/RadioGroup'
import DualRange from '../../../components/DualRange'
import ColorPicker from '../../../components/ColorPicker'

import OverlayAvatars from './OverlayAvatars'
import CommentOverlay from './CommentOverlay'
import CommentSuggestionOverlay from './CommentSuggestionOverlay'
import HistoryOverlay from './HistoryOverlay'
import Background from './Background'
import Shortcuts from './Shortcuts'
import CollapsibleCommentOverlay from './CollapsibleCommentOverlay'
import CollapsibleImageInfo from './CollapsibleImageInfo'
import Comment from './Comment'

import { ReactComponent as OverlayCloseIconSvg } from '../../../svg/overlay_close_icon.svg'
import { ReactComponent as OverlayLeftArrowIconSvg } from '../../../svg/overlay_right_arrow_icon.svg'
import { ReactComponent as OverlayCompareIconSvg } from '../../../svg/overlay_compare_icon.svg'
import { ReactComponent as OverlayDrawingIconSvg } from '../../../svg/overlay_drawing_icon.svg'
import { ReactComponent as OverlayDragIconSvg } from '../../../svg/overlay_drag_icon.svg'
import { ReactComponent as OverlayMagnifyIconSvg } from '../../../svg/overlay_magnify_icon.svg'
import { ReactComponent as OverlayNextIconSvg } from '../../../svg/overlay_next_icon.svg'
import { ReactComponent as OverlayPrevIconSvg } from '../../../svg/overlay_prev_icon.svg'
import { ReactComponent as CategoryIconSvg } from '../../../svg/category.svg'
import { ReactComponent as RedoIconSvg } from '../../../svg/repeat.svg'
import { ReactComponent as RocketIconSvg } from '../../../svg/rocket.svg'
import { ReactComponent as PathOutsideImageIconSvg } from '../../../svg/path_outside_image.svg'
import { ReactComponent as LayerMaskIconSvg } from '../../../svg/layer_mask.svg'
import { ReactComponent as LayerVisibleIconSvg } from '../../../svg/layer_visible.svg'
import { ReactComponent as LayerInvisibleIconSvg } from '../../../svg/layer_invisible.svg'

import './index.scss'

const Overlay = ({ refreshGallery, resetInterval }) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isOverlayOpened: state.layout.isOverlayOpened,
    isShortcutsOpened: state.layout.isShortcutsOpened,
    leftPanelWidth: state.layout.leftPanelWidth,
    rightPanelWidth: state.layout.rightPanelWidth,
    isRedoModalOpened: state.layout.isRedoModalOpened,
    pathsColors: state.layout.pathsColors,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateOverlayOpened: actions.layout.updateOverlayOpened,
    updateShortcutsOpened: actions.layout.updateShortcutsOpened,
    updateRedoModalOpened: actions.layout.updateRedoModalOpened,
    updatePathsColors: actions.layout.updatePathsColors,
  }))

  const orderState = useStoreState((state) => ({
    images: state.order.images,
    imageDetails: state.order.imageDetails,
    overlayImgIndex: state.order.overlayImgIndex,
    imageType: state.order.imageType,
    layerUrls: state.order.layerUrls,
    paths: state.order.paths,
    pathRange: state.order.pathRange,
    gallery: state.order.gallery,
    selectedImagePaths: state.order.selectedImagePaths,
    selectedGrids: state.order.selectedGrids,
    orderViewingUsers: state.order.orderViewingUsers,
    selectedLayers: state.order.selectedLayers,
    drawingColor: state.order.drawingColor,
    selectedUnderlayUrl: state.order.selectedUnderlayUrl,
    selectedUnderlayWidth: state.order.selectedUnderlayWidth,
    selectedUnderlayHeight: state.order.selectedUnderlayHeight,
    drawingType: state.order.drawingType,
    galleryBackground: state.order.galleryBackground,
    previewsBackground: state.order.previewsBackground,
  }))

  const orderActions = useStoreActions((actions) => ({
    setOverlayImgIndex: actions.order.setOverlayImgIndex,
    getImageDetails: actions.order.getImageDetails,
    removeError: actions.order.removeError,
    getPath: actions.order.getPath,
    setPathRange: actions.order.setPathRange,
    setSelectedImagePaths: actions.order.setSelectedImagePaths,
    setSelectedGrids: actions.order.setSelectedGrids,
    setSelectedLayers: actions.order.setSelectedLayers,
    setDrawingColor: actions.order.setDrawingColor,
    setDrawingType: actions.order.setDrawingType,
  }))

  const initOverlayImgPos = { top: 0, left: 0 }
  const fullZoomValue = 100
  const minContrast = 0
  const maxContrast = 255

  const [overlayImgSize, setOverlayImgSize] = useState(DEFAULT_IMAGE_SIZES)
  const [zoomValue, setZoomValue] = useState(fullZoomValue)
  const [isNotFound, setIsNotFound] = useState(false)
  const [isBroken, setIsBroken] = useState(false)
  const [imageElements, setImageElements] = useState({})
  const [isOriginalImage, setIsOriginalImage] = useState(false)
  const [isSpaceRequired, setIsSpaceRequired] = useState(true)
  const [originalImageDetails, setOriginalImageDetails] = useState({})
  const [imagePaths, setImagePaths] = useState({})
  const [layerValues, setLayerValues] = useState({})
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const [colorSelectingPath, setColorSelectingPath] = useState(null)
  const [isMagnifierActive, setIsMagnifierActive] = useState(false)
  const [underlayStyle, setUnderlayStyle] = useState({})
  const [contrastValue, setContrastValue] = useState({ min: minContrast, max: maxContrast })

  const showInterfaceInit = JSON.parse(localStorage.getItem('showInterface')) !== null
    ? JSON.parse(localStorage.getItem('showInterface')) : true
  const [showInterface, setShowInterface] = useState(showInterfaceInit)

  const [isExpanded, setIsExpanded] = useState(
    localStorage.getItem('isExpanded')
      ? JSON.parse(localStorage.getItem('isExpanded'))
      : {
        imageInformation: true,
        contrast: true,
        background: true,
        grids: true,
        paths: true,
        layers: true,
        alphaChannel: true,
        history: true,
        commentSuggestion: true,
      },
  )

  const overlayContainerRef = useRef(null)
  const overlayImageWrapRef = useRef(null)
  const overlayImgRef = useRef(null)
  const overlayImgIndexRef = useRef(null)
  const overlayImgContrastRef = useRef(null)
  const overlayImgDrawingRef = useRef(null)
  const imageOverlayImgCorsRef = useRef(null)
  const dragEventRef = useRef(null)
  const originalImageRef = useRef(null)
  const originalImageDataRef = useRef(null)
  const contrastCtxRef = useRef(null)

  const isMagnifierInitRef = useRef(false)
  const isMagnifierActiveRef = useRef(false)
  const magnifierOverlayRef = useRef(null)
  const resizeRatioRef = useRef(null)

  const wheelScaleRef = useRef(1)
  const minWheelScaleRef = useRef(1)
  const zoomDeltaRef = useRef(0) // To accumulate delta values
  const wheelAnimFrameIdRef = useRef(null) // To store requestAnimationFrame ID
  const mouseXRef = useRef(0) // Mouse X position relative to the image
  const mouseYRef = useRef(0) // Mouse Y position relative to the image

  useEffect(() => {
    localStorage.setItem('showInterface', showInterface)
  }, [showInterface])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    overlayImgIndexRef.current = orderState.overlayImgIndex
  }, [orderState.overlayImgIndex])

  const getDetails = (index = null, isOriginal = false) => {
    let previousImage = {}
    if (index || orderState.overlayImgIndex > 0) previousImage = orderState.images[(index || orderState.overlayImgIndex) - 1]

    let nextImage = {}
    if (index || orderState.overlayImgIndex < (orderState.images.length - 1)) {
      nextImage = orderState.images[(index || orderState.overlayImgIndex) + 1]
    }

    const outPayload = {
      image_ids: [],
      order_ids: [],
      image_type: orderState.imageType,
      image_size: IMAGE_SIZES.extraLarge,
    }

    if (previousImage.id && !orderState.imageDetails[previousImage.id]) {
      outPayload.image_ids.push(previousImage.id)
      outPayload.order_ids.push(previousImage.order_id)
    }

    if (!orderState.imageDetails[orderState.images[index || orderState.overlayImgIndex].id]) {
      outPayload.image_ids.push(orderState.images[index || orderState.overlayImgIndex].id)
      outPayload.order_ids.push(orderState.images[index || orderState.overlayImgIndex].order_id)
    }

    if (nextImage?.id && !orderState.imageDetails[nextImage.id]) {
      outPayload.image_ids.push(nextImage.id)
      outPayload.order_ids.push(nextImage.order_id)
    }

    if (outPayload.image_ids.length) orderActions.getImageDetails(outPayload)

    if (isOriginal) {
      const inPayload = {
        image_ids: [],
        order_ids: [],
        image_type: IMAGE_TYPES.input,
        image_size: IMAGE_SIZES.extraLarge,
      }

      if (previousImage.id && !orderState.imageDetails[previousImage.original_image_id]) {
        inPayload.image_ids.push(parseInt(previousImage.original_image_id, 10))
        inPayload.order_ids.push(previousImage.order_id)
      }

      if (!orderState.imageDetails[orderState.images[index || orderState.overlayImgIndex].original_image_id]) {
        inPayload.image_ids.push(parseInt(orderState.images[index || orderState.overlayImgIndex].original_image_id, 10))
        inPayload.order_ids.push(orderState.images[index || orderState.overlayImgIndex].order_id)
      }

      if (nextImage?.id && !orderState.imageDetails[nextImage.original_image_id]) {
        inPayload.image_ids.push(parseInt(nextImage.original_image_id, 10))
        inPayload.order_ids.push(nextImage.order_id)
      }

      if (inPayload.image_ids.length) orderActions.getImageDetails(inPayload)
    }
  }

  const getOverlayContainerSizes = () => {
    let width = overlayContainerRef.current?.offsetWidth || 0
    let height = overlayContainerRef.current?.offsetHeight || 0

    if (width) width -= (2 * IMAGE_OVERLAY_BORDER)
    if (height) height -= (2 * IMAGE_OVERLAY_BORDER)

    return { width, height }
  }

  const getCenterPosition = () => {
    const pos = initOverlayImgPos

    const overlayContainerSizes = getOverlayContainerSizes()
    if (overlayContainerSizes.width <= 0 || overlayContainerSizes.height <= 0) return pos

    let scrollLeft = parseFloatRound((overlayImgSize.width - overlayContainerSizes.width) / 2)
    let scrollTop = parseFloatRound((overlayImgSize.height - overlayContainerSizes.height) / 2)

    scrollLeft -= IMAGE_OVERLAY_BORDER
    scrollTop -= IMAGE_OVERLAY_BORDER

    pos.left = -1 * scrollLeft
    pos.top = -1 * scrollTop

    return pos
  }

  const positionToCenter = (shiftTop = 0, shiftLeft = 0) => {
    const pos = getCenterPosition()
    overlayImageWrapRef.current.style.top = isBroken ? '0' : `${pos.top + shiftTop}px`
    overlayImageWrapRef.current.style.left = isBroken ? '0' : `${pos.left + shiftLeft}px`
  }

  const getImageSizes = () => {
    // eslint-disable-next-line
    const width = originalImageRef.current?.isOriginalImage
      ? originalImageRef.current.originalImageDetails?.width
      : (orderState.images[overlayImgIndexRef.current]?.width)
        ? orderState.images[overlayImgIndexRef.current]?.width
        : DEFAULT_IMAGE_SIZES.width

    // eslint-disable-next-line
    const height = originalImageRef.current?.isOriginalImage
      ? originalImageRef.current.originalImageDetails?.height
      : (orderState.images[overlayImgIndexRef.current]?.height)
        ? orderState.images[overlayImgIndexRef.current]?.height
        : DEFAULT_IMAGE_SIZES.height

    return { width, height }
  }

  const getResizeRatio = () => {
    let ratio = 1

    if (!orderState.images[overlayImgIndexRef.current]?.width
      || !orderState.images[overlayImgIndexRef.current]?.height) return ratio

    const overlayContainerSizes = getOverlayContainerSizes()
    if (overlayContainerSizes.width <= 0 || overlayContainerSizes.height <= 0) return ratio

    const imageSizes = getImageSizes()

    const ratioW = overlayContainerSizes.width / imageSizes.width
    const ratioH = overlayContainerSizes.height / imageSizes.height

    ratio = ratioW > ratioH ? ratioH : ratioW
    if (ratio > 1) ratio = 1

    return ratio
  }

  const updateGrids = () => {
    orderState.selectedGrids.forEach((selectedGrid) => {
      const gridIndex = orderState.gallery?.grids?.findIndex((grid) => grid.name === selectedGrid.name)

      document.querySelectorAll(`.${OVERLAY_GRID_CLASS}.grid-${gridIndex}`).forEach((gridElement) => {
        updateGrid(
          gridElement,
          orderState.images[orderState.overlayImgIndex].width,
          orderState.images[orderState.overlayImgIndex].height,
        )

        gridElement.querySelectorAll(`.${VERTICAL_GRID_CLASS}`).forEach((lineElement, i) => {
          updateVerticalGridLine(
            lineElement,
            orderState.gallery?.grids[gridIndex].left[i],
            orderState.images[orderState.overlayImgIndex].width,
            orderState.images[orderState.overlayImgIndex].height,
          )
        })

        gridElement.querySelectorAll(`.${HORIZONTAL_GRID_CLASS}`).forEach((lineElement, i) => {
          updateHorizontalGridLine(
            lineElement,
            orderState.gallery?.grids[gridIndex].top[i],
            orderState.images[orderState.overlayImgIndex].width,
            orderState.images[orderState.overlayImgIndex].height,
          )
        })
      })
    })
  }

  const resizeUnderlay = () => {
    const ratio = getResizeRatio()

    const imageWidth = orderState.images[orderState.overlayImgIndex].width * ratio
    const imageHeight = orderState.images[orderState.overlayImgIndex].height * ratio

    const ratioW = imageWidth / orderState.selectedUnderlayWidth
    const ratioH = imageHeight / orderState.selectedUnderlayHeight

    let ratioUnderlay = (ratioW > ratioH) ? ratioH : ratioW
    if (ratioUnderlay > 1) ratioUnderlay = 1

    const width = parseFloatRound(orderState.selectedUnderlayWidth * ratioUnderlay)
    const height = parseFloatRound(orderState.selectedUnderlayHeight * ratioUnderlay)

    const left = imageWidth > width
      ? `${parseFloatRound((imageWidth - width) / 2)}` : 0
    const top = imageHeight > height
      ? `${parseFloatRound((imageHeight - height) / 2)}` : 0

    setUnderlayStyle({
      width: `${width}px`,
      height: `${height}px`,
      left: `${left}px`,
      top: `${top}px`,
    })
  }

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    setImagePaths(orderState.images[orderState.overlayImgIndex].path)
    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => path.remove())

    updateGrids()
    resizeUnderlay()

    addClass(overlayImgRef.current, SKELETON_CLASS)
    if (overlayImgRef.current?.src?.includes(notFoundPreview) || overlayImgRef.current?.src?.includes(brokenFilePreview)) {
      removeClass(overlayImgRef.current, SKELETON_CLASS)
    }
  }, [layoutState.isOverlayOpened, orderState.overlayImgIndex])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return
    setOriginalImageDetails(orderState.imageDetails[orderState.images[orderState.overlayImgIndex].original_image_id])
  }, [orderState.imageDetails, orderState.overlayImgIndex])

  useEffect(() => {
    const layers = {}
    orderState.images[orderState.overlayImgIndex]?.layer?.forEach((layer) => {
      layers[layer.id] = (
        <div className="layer-item">
          {parseInt(layer.is_visible, 2) ? <LayerVisibleIconSvg /> : <LayerInvisibleIconSvg />}
          {parseInt(layer.has_layer_mask, 2) ? <LayerMaskIconSvg /> : null}
          <Typography label={layer.name.replace(/&nbsp;/g, ' ')} />
        </div>
      )
    })
    setLayerValues({
      [`${ALL_LAYERS_KEY}-${orderState.images[orderState.overlayImgIndex]?.id}`]: (
        <div className="layer-item">
          <Typography label={Translation.all_layers} />
        </div>
      ),
      ...layers,
    })
  }, [orderState.images[orderState.overlayImgIndex]])

  useEffect(() => {
    Object.keys(orderState.imageDetails).forEach((detail) => {
      if (detail) {
        const image = new Image()
        image.src = orderState.imageDetails[detail].url
        image.onload = () => {
          setImageElements((prevImageElements) => ({
            ...prevImageElements,
            [orderState.imageDetails[detail].id]: image,
          }))
        }
        image.onerror = () => {
          if (parseInt(orderState.imageDetails[detail].status, 10) === IMAGE_STATUSES.broken) {
            image.src = brokenFilePreview
          } else {
            image.src = notFoundPreview
          }
        }
      }
    })
  }, [orderState.imageDetails])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    positionToCenter()

    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => {
      path?.setAttribute('style', `width: ${overlayImgSize.width}px; height: ${overlayImgSize.height}px;`)
    })

    document.querySelectorAll(`.${OVERLAY_GRID_CLASS}`).forEach((grid) => {
      grid?.setAttribute('style', `width: ${overlayImgSize.width}px; height: ${overlayImgSize.height}px;`)
    })
  }, [overlayImgSize])

  useEffect(() => {
    if (overlayImgRef.current?.src?.includes(notFoundPreview)) {
      setIsNotFound(true)
    } else if (overlayImgRef.current?.src?.includes(brokenFilePreview)) {
      setIsBroken(true)
    } else {
      setIsNotFound(false)
      setIsBroken(false)
    }
  }, [overlayImgRef.current?.src])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    contrastCtxRef.current = overlayImgContrastRef.current.getContext('2d', {
      willReadFrequently: true,
    })
    contrastCtxRef.current.imageSmoothingEnabled = false
  }, [])

  const renderImageInCanvas = () => {
    contrastCtxRef.current.clearRect(
      0,
      0,
      overlayImgContrastRef.current.width,
      overlayImgContrastRef.current.height,
    )
    contrastCtxRef.current.drawImage(
      imageOverlayImgCorsRef.current,
      0,
      0,
      overlayImgContrastRef.current.width,
      overlayImgContrastRef.current.height,
    )
    originalImageDataRef.current = contrastCtxRef.current.getImageData(
      0,
      0,
      overlayImgContrastRef.current.width,
      overlayImgContrastRef.current.height,
    )
  }

  const createImageWithCorsForContrast = (url) => {
    if (!isExpanded.contrast) return // TODO: load cors image, on expanding this block
    if (!orderState.images[orderState.overlayImgIndex]?.id) return

    if (url.includes(EMPTY_IMG)) return
    if (url.includes(notFoundPreview)) return
    if (url.includes(brokenFilePreview)) return

    // Append something unique to the URL to force browser to load image again
    const urlForCors = `${url}?${orderState.images[orderState.overlayImgIndex]?.id}`

    imageOverlayImgCorsRef.current = new Image()
    imageOverlayImgCorsRef.current.crossOrigin = 'Anonymous' // Allows cross-origin loading
    imageOverlayImgCorsRef.current.src = urlForCors

    imageOverlayImgCorsRef.current.addEventListener('load', () => {
      renderImageInCanvas()
    })
  }

  const handleOverlayImgLoad = () => {
    if (overlayImgRef.current.src !== `${window.location.origin}${EMPTY_IMG}`) {
      removeClass(overlayImgRef.current, SKELETON_CLASS)
    }

    createImageWithCorsForContrast(overlayImgRef.current.src)
  }

  const handleOverlayImgLoadError = () => {
    if (orderState.selectedLayers[orderState.images[orderState.overlayImgIndex]?.id]
      !== `${ALL_LAYERS_KEY}-${orderState.images[orderState.overlayImgIndex]?.id}`) {
      removeClass(overlayImgRef.current, SKELETON_CLASS)
      document.querySelector(`.${OVERLAY_IMG_CLASS}`).src = notFoundPreview
    }
  }

  const resetContrast = () => {
    setContrastValue({ min: minContrast, max: maxContrast })
  }

  const handleNextPrevClick = async (e) => {
    const { direction } = e.currentTarget.dataset
    if (direction === '-1' && orderState.overlayImgIndex === 0) return
    if (direction === '1' && orderState.overlayImgIndex === orderState.images.length - 1) return
    orderActions.setOverlayImgIndex(orderState.overlayImgIndex + parseInt(direction, 10))
    getDetails(orderState.overlayImgIndex + parseInt(direction, 10), isOriginalImage)
    resetContrast()
  }

  const onCloseOverlay = () => {
    layoutActions.updateOverlayOpened(false)
  }

  useEventListener('keyup', (e) => {
    if (!layoutState.isOverlayOpened) return

    if (e.target.tagName === 'TEXTAREA') return

    if (e.key === 'ArrowLeft') {
      if (orderState.overlayImgIndex > 0) {
        handleNextPrevClick({ currentTarget: { dataset: { direction: '-1' } } }).then(() => {
        })
      }
    } else if (e.key === 'ArrowRight') {
      if (orderState.overlayImgIndex < orderState.images.length - 1) {
        handleNextPrevClick({ currentTarget: { dataset: { direction: '1' } } }).then(() => {
        })
      }
    } else if (e.key === 'Escape') {
      onCloseOverlay()
    }
  })

  const toggleSpaceRequired = () => {
    setIsSpaceRequired(!isSpaceRequired)

    overlayImageWrapRef?.current.removeEventListener('mousedown', dragEventRef.current)
    dragEventRef.current = dragElement(overlayImageWrapRef.current, !isSpaceRequired)
    overlayImageWrapRef.current.addEventListener('mousedown', dragEventRef.current)
  }

  const toggleOriginalImage = () => {
    if (orderState.images[orderState.overlayImgIndex].original_image_id) {
      addClass(document.querySelector(`.${OVERLAY_IMG_CLASS}`), SKELETON_CLASS)
      setIsOriginalImage(!isOriginalImage)
      getDetails(orderState.overlayImgIndex, true)
    }
  }

  const handleOverlayDrawingClick = () => {
    if (orderState.drawingType) {
      orderActions.setDrawingType(null)
    } else {
      orderActions.setDrawingType(DEFAULT_DRAWING_TYPE)
    }
  }

  useEffect(() => {
    if (originalImageDetails?.width && originalImageDetails?.height) {
      originalImageRef.current = {
        isOriginalImage,
        originalImageDetails,
      }
    }
  }, [isOriginalImage, originalImageDetails, orderState.overlayImgIndex])

  const expand = (key) => {
    setIsExpanded(() => ({
      ...isExpanded,
      [key]: !isExpanded[key],
    }))

    localStorage.setItem('isExpanded', JSON.stringify(
      {
        ...isExpanded,
        [key]: !isExpanded[key],
      },
    ))
  }

  const removeErrorCode = async (e, errorCode) => {
    if (!userState.user.is_admin) return

    if (isAltKey(e)) {
      e.stopPropagation()

      if (errorCode === IMAGE_ERROR_CODES.wrongFileName) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (!orderState.images[orderState.overlayImgIndex].wrong_format) {
          removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
        }
      } else if (errorCode === IMAGE_ERROR_CODES.wrongFormat) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (!orderState.images[orderState.overlayImgIndex].wrong_file_name) {
          removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
        }
      } else if (errorCode === IMAGE_ERROR_CODES.colorSpaceError || errorCode === IMAGE_ERROR_CODES.noBgColor) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
      } else if (errorCode === IMAGE_ERROR_CODES.widthError) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (
          !orderState.images[orderState.overlayImgIndex].height_error
          && !orderState.images[orderState.overlayImgIndex].dpi_error
        ) {
          removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
        }
      } else if (errorCode === IMAGE_ERROR_CODES.heightError) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (
          !orderState.images[orderState.overlayImgIndex].width_error
          && !orderState.images[orderState.overlayImgIndex].dpi_error
        ) {
          removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
        }
      } else if (errorCode === IMAGE_ERROR_CODES.dpiError) {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
        if (
          !orderState.images[orderState.overlayImgIndex].width_error
          && !orderState.images[orderState.overlayImgIndex].height_error
        ) {
          removeClass(e.currentTarget.parentElement, INFO_ERROR_CLASS)
        }
      } else {
        removeClass(e.currentTarget, IMAGE_ERROR_CLASS)
      }

      const res = await orderActions.removeError({
        is_admin: userState.user.is_admin,
        body: {
          image_ids: [orderState.images[orderState.overlayImgIndex].id],
          error_codes: [errorCode],
        },
      })
      if (res) {
        refreshGallery()
        resetInterval()
      }
    }
  }

  const onChangePathRange = (value) => {
    orderActions.setPathRange(parseInt(value, 10))
  }

  const onSelectPath = async (path) => {
    const selectedImagePathsCopy = { ...orderState.selectedImagePaths }
    if (!selectedImagePathsCopy[orderState.images[orderState.overlayImgIndex].id]) {
      selectedImagePathsCopy[orderState.images[orderState.overlayImgIndex].id] = {}
    }
    selectedImagePathsCopy[orderState.images[orderState.overlayImgIndex].id][path] = !selectedImagePathsCopy[
      orderState.images[orderState.overlayImgIndex].id
    ][path]
    orderActions.setSelectedImagePaths(selectedImagePathsCopy)

    if (!selectedImagePathsCopy?.[orderState.images[orderState.overlayImgIndex].id]?.[path]) {
      document.getElementById(`${orderState.images[orderState.overlayImgIndex].id}-${path}`)?.remove()
      document.getElementById(`${orderState.images[orderState.overlayImgIndex].id}-${path}-preview`)?.remove()
      return
    }
    if (orderState.paths[orderState.images[orderState.overlayImgIndex].id]?.[imagePaths[path]?.line.s3_path]) {
      return
    }

    await orderActions.getPath({
      image_ids: [
        orderState.images[orderState.overlayImgIndex].id,
      ],
      s3_paths: [
        imagePaths[path]?.line.s3_path,
      ],
    })
  }

  const addPath = (pathName) => {
    const elementString = orderState.paths[orderState.images[orderState.overlayImgIndex].id]
      ?.[orderState.images[orderState.overlayImgIndex].path?.[pathName]?.line?.s3_path]
    if (!elementString || document.getElementById(`${orderState.images[orderState.overlayImgIndex].id}-${pathName}`)) return

    const svgElement = buildPath(
      `${orderState.images[orderState.overlayImgIndex].id}-${pathName}`,
      SHOW_PATH_CLASS,
      elementString,
      overlayImgRef.current.width,
      overlayImgRef.current.height,
      orderState.pathRange,
      pathName,
    )
    document.querySelector(`.${OVERLAY_IMG_CLASS}`).insertAdjacentElement('afterend', svgElement)
  }

  const onSelectGrid = (grid) => {
    const newSelectedGrids = [...orderState.selectedGrids]
    const index = newSelectedGrids.findIndex((selectedGrid) => selectedGrid.name === grid.name)
    if (index > -1) {
      newSelectedGrids.splice(index, 1)
      document
        .querySelector(`.${OVERLAY_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
        ?.remove()
      document
        .querySelectorAll(`.${OVERLAY_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
        ?.forEach((gridElement) => {
          gridElement?.remove()
        })
    } else {
      newSelectedGrids.push(grid)
    }
    orderActions.setSelectedGrids(newSelectedGrids)
  }

  const toggleInterface = () => {
    setShowInterface((prev) => !prev)

    setTimeout(() => {
      // resizeLayers()
      if (showInterface) {
        positionToCenter(0, (layoutState.leftPanelWidth - layoutState.rightPanelWidth) / 2)
      } else {
        positionToCenter()
      }
    }, 10)
  }

  const toggleShortcuts = () => {
    layoutActions.updateShortcutsOpened(!layoutState.isShortcutsOpened)
  }

  const sendInRedo = () => {
    if (!orderState.gallery?.is_send_into_redo) return

    layoutActions.updateRedoModalOpened(true)
  }

  const onHandleLayerChange = (e) => {
    e.preventDefault()
    e.stopPropagation()
    orderActions.setSelectedLayers({
      imageId: orderState.images[orderState.overlayImgIndex].id,
      layerId: e.target.value,
    })
  }

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    orderState.selectedGrids.forEach((grid) => {
      if (document
        .querySelector(`.${OVERLAY_GRID_CLASS}.grid-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`)
      ) return

      const color = PATH_COLORS[orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)]
      const ratio = getResizeRatio()

      const svgElement = buildGrids(
        grid,
        orderState.gallery?.grids,
        orderState.images[orderState.overlayImgIndex].width,
        orderState.images[orderState.overlayImgIndex].height,
        parseFloatRound(orderState.images[orderState.overlayImgIndex].width * ratio),
        parseFloatRound(orderState.images[orderState.overlayImgIndex].height * ratio),
        color,
        OVERLAY_GRID_CLASS,
      )

      document.querySelector(`.${OVERLAY_IMG_CLASS}`).insertAdjacentElement('afterend', svgElement)
    })
  }, [orderState.selectedGrids])

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return
    if (!orderState.paths[orderState.images[orderState.overlayImgIndex]?.id]) return
    Object.keys(orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex]?.id] || {})
      .forEach((pathName) => {
        if (orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex]?.id]?.[pathName]) {
          addPath(pathName)
        }
      })
  }, [
    orderState.paths,
    orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex]?.id],
    layoutState.isOverlayOpened,
  ])

  useEffect(() => {
    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => {
      path?.querySelector('path').setAttribute('stroke-width', orderState.pathRange / PATH_DISPLAY_RATIO)
    })
  }, [orderState.pathRange])

  useEffect(() => {
    document.querySelectorAll(`.${SHOW_PATH_CLASS}`).forEach((path) => {
      if (layoutState.pathsColors[path.dataset.pathName]) {
        path.querySelector('path').setAttribute('stroke', layoutState.pathsColors[path.dataset.pathName])
      }
    })
  }, [layoutState.pathsColors])

  const fileName = () => (
    <div
      className={
        (isOriginalImage
          ? (originalImageDetails?.wrong_file_name || originalImageDetails?.wrong_format)
          : (orderState.images[orderState.overlayImgIndex].wrong_file_name
            || orderState.images[orderState.overlayImgIndex].wrong_format))
          ? 'info info-error'
          : 'info'
      }
    >
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.wrong_file_name
            : orderState.images[orderState.overlayImgIndex].wrong_file_name)
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.wrongFileName)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.name_without_format
            : orderState.images[orderState.overlayImgIndex].name_without_format
        }
      </span>
      <span>&nbsp;.&nbsp;</span>
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.wrong_format
            : orderState.images[orderState.overlayImgIndex].wrong_format)
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.wrongFormat)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.format
            : orderState.images[orderState.overlayImgIndex].format
        }
      </span>
    </div>
  )

  const showFileName = () => {
    if (isExpanded.imageInformation) {
      return true
    }

    const imageDetails = isOriginalImage ? originalImageDetails : orderState.images[orderState.overlayImgIndex]
    return imageDetails?.wrong_file_name || imageDetails?.wrong_format
  }

  const imageDimensions = () => (
    <div
      className={
        (isOriginalImage
          ? (originalImageDetails?.width_error
            || originalImageDetails?.height_error
            || originalImageDetails?.dpi_error)
          : (orderState.images[orderState.overlayImgIndex].width_error
            || orderState.images[orderState.overlayImgIndex].height_error
            || orderState.images[orderState.overlayImgIndex].dpi_error))
          ? 'info info-error'
          : 'info'
      }
    >
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.width_error
            : orderState.images[orderState.overlayImgIndex].width_error)
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.widthError)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.width
            : orderState.images[orderState.overlayImgIndex].width
        }
      </span>
      <span>&nbsp;x&nbsp;</span>
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.height_error
            : orderState.images[orderState.overlayImgIndex].height_error)
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.heightError)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.height
            : orderState.images[orderState.overlayImgIndex].height
        }
      </span>
      <span>
        &nbsp;
        {Translation.px}
        &nbsp;
        /
        &nbsp;
      </span>
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.dpi_error
            : orderState.images[orderState.overlayImgIndex].dpi_error)
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.dpiError)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.dpi
            : orderState.images[orderState.overlayImgIndex].dpi
        }
      </span>
      <span>
        &nbsp;
        {Translation.dpi}
        &nbsp;
        /
        &nbsp;
        {
          isOriginalImage
            ? originalImageDetails?.size_kb
            : orderState.images[orderState.overlayImgIndex].size_kb
        }
        &nbsp;
        {Translation.kb}
      </span>
    </div>
  )

  const showImageDimensions = () => {
    if (isExpanded.imageInformation) {
      return true
    }

    const imageDetails = isOriginalImage ? originalImageDetails : orderState.images[orderState.overlayImgIndex]
    return imageDetails?.width_error || imageDetails?.height_error || imageDetails?.dpi_error
  }

  const colorSpace = () => (
    <div
      className={
        (isOriginalImage
          ? originalImageDetails?.color_space_error
          : orderState.images[orderState.overlayImgIndex].color_space_error)
          ? 'info info-error'
          : 'info'
      }
    >
      <span
        className={
          (isOriginalImage
            ? originalImageDetails?.color_space_error
            : orderState.images[orderState.overlayImgIndex].color_space_error)
            ? IMAGE_ERROR_CLASS
            : ''
        }
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.colorSpaceError)}
      >
        {
          isOriginalImage
            ? originalImageDetails?.colour_space
            : orderState.images[orderState.overlayImgIndex].colour_space
        }
      </span>
    </div>
  )

  const showColorSpace = () => {
    if (isExpanded.imageInformation) {
      return true
    }

    const imageDetails = isOriginalImage ? originalImageDetails : orderState.images[orderState.overlayImgIndex]
    return imageDetails?.color_space_error
  }

  const backgroundColor = () => (
    <div
      className={
        (isOriginalImage
          ? originalImageDetails?.no_bg_color
          : orderState.images[orderState.overlayImgIndex].no_bg_color)
          ? 'info info-error'
          : 'info'
      }
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {(isOriginalImage
        ? originalImageDetails?.no_bg_color
        : orderState.images[orderState.overlayImgIndex].no_bg_color)
        ? (
          <span
            className={
              (isOriginalImage
                ? originalImageDetails?.no_bg_color
                : orderState.images[orderState.overlayImgIndex].no_bg_color)
                ? IMAGE_ERROR_CLASS
                : ''
            }
            onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.noBgColor)}
          >
            {
              (isOriginalImage
                ? originalImageDetails?.background_color
                : orderState.images[orderState.overlayImgIndex].background_color)
                ? `
                ${Translation.detected_background}: ${(isOriginalImage
                  ? originalImageDetails?.background_color
                  : orderState.images[orderState.overlayImgIndex].background_color
                )}
                `
                : Translation.background_colour_not_detected
            }
          </span>
        ) : (isOriginalImage
          ? originalImageDetails?.background_color
          : orderState.images[orderState.overlayImgIndex].background_color)
          ? `
          ${Translation.detected_background}: ${(isOriginalImage
            ? originalImageDetails?.background_color
            : orderState.images[orderState.overlayImgIndex].background_color
          )}
          `
          : Translation.background_colour_not_detected}
    </div>
  )

  const showBackgroundColor = () => {
    if (isExpanded.imageInformation) {
      return true
    }

    const imageDetails = isOriginalImage ? originalImageDetails : orderState.images[orderState.overlayImgIndex]
    return imageDetails?.no_bg_color
  }

  const closePicker = () => {
    setColorSelectingPath(null)
    setOpenColorPicker(false)
  }

  const handlePathColorClick = (path) => {
    setColorSelectingPath(path)
    setOpenColorPicker(true)
  }

  const handlePathColorSelect = (color) => {
    layoutActions.updatePathsColors({
      ...layoutState.pathsColors,
      [colorSelectingPath]: color,
    })
    setColorSelectingPath(null)
    setOpenColorPicker(false)
  }

  const resize = (element, width, height) => {
    if (!element) return
    // eslint-disable-next-line no-param-reassign
    element.style.width = `${width}px`
    // eslint-disable-next-line no-param-reassign
    element.style.height = `${height}px`
  }

  const setViewBox = (element) => {
    element?.setAttribute(
      'viewBox',
      `0 0 
       ${orderState.images[overlayImgIndexRef.current]?.width || 0} 
       ${orderState.images[overlayImgIndexRef.current]?.height || 0}`,
    )
  }

  const resizeSvg = (element, width, height) => {
    if (element) {
      setViewBox(element, width, height)

      // TODO: looks like not need for SVG, because resized correctly with only viewBox
      // element?.setAttribute('width', width)
      // element?.setAttribute('height', height)

      // eslint-disable-next-line no-param-reassign
      element.style.width = `${width}px`
      // eslint-disable-next-line no-param-reassign
      element.style.height = `${height}px`
    }
  }

  const position = (element, left, top) => {
    // eslint-disable-next-line no-param-reassign
    element.style.left = `${left}px`
    // eslint-disable-next-line no-param-reassign
    element.style.top = `${top}px`
  }

  const resizeAndPosition = (element, width, height, left, top) => {
    resize(element, width, height)
    position(element, left, top)
  }

  const getSizesAndPosition = (isToOriginalSize = false) => {
    const imageSizes = getImageSizes()
    const newResizeRatio = getResizeRatio()

    if (isToOriginalSize) {
      resizeRatioRef.current = 1
      wheelScaleRef.current = 1
      minWheelScaleRef.current = newResizeRatio
      setOverlayImgSize(imageSizes)
    } else {
      resizeRatioRef.current = newResizeRatio
      wheelScaleRef.current = newResizeRatio
      minWheelScaleRef.current = newResizeRatio
      setOverlayImgSize({
        width: parseFloatRound(imageSizes.width * newResizeRatio),
        height: parseFloatRound(imageSizes.height * newResizeRatio),
      })
    }

    setZoomValue(Math.floor(parseFloatRound(resizeRatioRef.current * 100)))

    const width = parseFloatRound((orderState.images[overlayImgIndexRef.current]?.width || 0) * resizeRatioRef.current)
    const height = parseFloatRound((orderState.images[overlayImgIndexRef.current]?.height || 0) * resizeRatioRef.current)

    const overlayContainerSizes = getOverlayContainerSizes()

    const scrollLeft = parseFloatRound((overlayContainerSizes.width - width) / 2)
    const scrollTop = parseFloatRound((overlayContainerSizes.height - height) / 2)

    return {
      width, height, scrollLeft, scrollTop,
    }
  }

  const resizeAndPositionLayers = (isToOriginalSize = false) => {
    const {
      width, height, scrollLeft, scrollTop,
    } = getSizesAndPosition(isToOriginalSize)

    resizeAndPosition(overlayImageWrapRef.current, width, height, scrollLeft, scrollTop)
    resize(overlayImgRef.current, width, height)
    resize(overlayImgContrastRef.current, width, height)

    resizeUnderlay()
    resetContrast()
  }

  const applyZoom = () => {
    // Restrict scale between minWheelScale and SCALE_TIMES
    const newWheelScale = Math.min(
      Math.max(
        minWheelScaleRef.current,
        wheelScaleRef.current + zoomDeltaRef.current,
      ),
      SCALE_TIMES,
    )

    // If scale has not changed, skip updates and reset request
    if (newWheelScale === wheelScaleRef.current) {
      zoomDeltaRef.current = 0
      wheelAnimFrameIdRef.current = null
      return
    }

    // Calculate the new dimensions
    const width = parseFloatRound((orderState.images[overlayImgIndexRef.current]?.width || 0) * newWheelScale)
    const height = parseFloatRound((orderState.images[overlayImgIndexRef.current]?.height || 0) * newWheelScale)

    // Calculate the new position to keep the mouse point fixed
    const rect = overlayImageWrapRef.current.getBoundingClientRect()
    const dx = (mouseXRef.current / rect.width) * (width - rect.width)
    const dy = (mouseYRef.current / rect.height) * (height - rect.height)

    // Calculate the new left and top of the image
    const scrollLeft = parseFloatRound(overlayImageWrapRef.current.style.left || 0) - parseFloatRound(dx)
    const scrollTop = parseFloatRound(overlayImageWrapRef.current.style.top || 0) - parseFloatRound(dy)

    resizeAndPosition(overlayImageWrapRef.current, width, height, scrollLeft, scrollTop)
    resize(overlayImgRef.current, width, height)
    resize(overlayImgContrastRef.current, width, height)

    overlayImageWrapRef.current.querySelectorAll('svg').forEach((svg) => {
      resizeSvg(svg, width, height)
    })

    // Update the zoom scale and reset zoom delta
    wheelScaleRef.current = newWheelScale
    zoomDeltaRef.current = 0

    // Update zoom display
    setZoomValue(Math.floor(parseFloatRound(wheelScaleRef.current * 100)))

    // update resizeRation according to wheelScale
    resizeRatioRef.current = wheelScaleRef.current

    // Reset the animation frame ID
    wheelAnimFrameIdRef.current = null
  }

  const onMouseWheel = (e) => {
    e.preventDefault()

    // Accumulate delta for zoom level and get the mouse position
    zoomDeltaRef.current += -e.deltaY / ZOOM_SPEED

    const rect = overlayImageWrapRef.current.getBoundingClientRect()
    mouseXRef.current = e.clientX - rect.left
    mouseYRef.current = e.clientY - rect.top

    // Request an animation frame if not already requested
    if (!wheelAnimFrameIdRef.current) {
      wheelAnimFrameIdRef.current = requestAnimationFrame(applyZoom)
    }
  }

  const onMouseMove = (e) => {
    if (!isMagnifierActiveRef.current) return

    const imageRect = overlayImgRef.current.getBoundingClientRect()
    const magnifierRect = magnifierOverlayRef.current.getBoundingClientRect()

    const imageWidth = orderState.images[overlayImgIndexRef.current]?.width || 0
    const imageHeight = orderState.images[overlayImgIndexRef.current]?.height || 0

    const scaleX = imageWidth / imageRect.width
    const scaleY = imageHeight / imageRect.height

    const scrollLeft = parseFloatRound(e.clientX - magnifierRect.width / 2)
    const scrollTop = parseFloatRound(e.clientY - magnifierRect.height / 2)

    const offsetX = -1
      * (parseFloatRound((e.clientX - imageRect.left) * scaleX)
        - parseFloatRound(magnifierRect.width / 2))
    const offsetY = -1
      * (parseFloatRound((e.clientY - imageRect.top) * scaleY)
        - parseFloatRound(magnifierRect.height / 2))

    const magnifierImage = magnifierOverlayRef.current.querySelector(`#${OVERLAY_IMG_CLASS}`)

    position(magnifierOverlayRef.current, scrollLeft, scrollTop)
    position(magnifierImage, offsetX, offsetY)

    const guide = magnifierOverlayRef.current.querySelector(`.${OVERLAY_GUIDE_CLASS}`)
    position(guide, offsetX, offsetY)

    const gridList = magnifierOverlayRef.current.querySelectorAll(`.${OVERLAY_GRID_CLASS}`)
    gridList.forEach((grid) => {
      position(grid, offsetX, offsetY)
    })

    const pathList = magnifierOverlayRef.current.querySelectorAll(`.${SHOW_PATH_CLASS}`)
    pathList.forEach((path) => {
      position(path, offsetX, offsetY)
    })
  }

  const onMouseEnter = () => {
    if (!isMagnifierActiveRef.current) return
    magnifierOverlayRef.current.style.display = 'block'
  }

  const onMouseLeave = () => {
    if (!isMagnifierActiveRef.current) return
    magnifierOverlayRef.current.style.display = 'none'
  }

  const initMagnifier = () => {
    if (isMagnifierInitRef.current) return

    isMagnifierInitRef.current = true

    const imageWidth = orderState.images[overlayImgIndexRef.current]?.width || 0
    const imageHeight = orderState.images[overlayImgIndexRef.current]?.height || 0

    const magnifierImage = magnifierOverlayRef.current.querySelector(`#${OVERLAY_IMG_CLASS}`)

    resize(magnifierImage, imageWidth, imageHeight)

    const gridList = magnifierOverlayRef.current.querySelectorAll(`.${OVERLAY_GRID_CLASS}`)
    gridList.forEach((grid) => {
      resizeSvg(grid, imageWidth, imageHeight)
    })

    const pathList = magnifierOverlayRef.current.querySelectorAll(`.${SHOW_PATH_CLASS}`)
    pathList.forEach((path) => {
      resizeSvg(path, imageWidth, imageHeight)
    })

    const guide = magnifierOverlayRef.current.querySelector(`.${OVERLAY_GUIDE_CLASS}`)
    resizeSvg(guide, imageWidth, imageHeight)
  }

  const handleMagnifier = () => {
    magnifierOverlayRef.current.innerHTML = overlayImageWrapRef.current.innerHTML
    isMagnifierActiveRef.current = !isMagnifierActiveRef.current
    setIsMagnifierActive(isMagnifierActiveRef.current)

    if (isMagnifierActiveRef.current) {
      overlayImageWrapRef.current.addEventListener('mousemove', onMouseMove)
      overlayImageWrapRef.current.addEventListener('mouseenter', onMouseEnter)
      overlayImageWrapRef.current.addEventListener('mouseleave', onMouseLeave)
      initMagnifier()
    } else {
      overlayImageWrapRef.current.removeEventListener('mousemove', onMouseMove)
      overlayImageWrapRef.current.removeEventListener('mouseenter', onMouseEnter)
      overlayImageWrapRef.current.removeEventListener('mouseleave', onMouseLeave)
      magnifierOverlayRef.current.style.display = 'none'
    }
  }

  const handleOverlayZoom = () => {
    if (resizeRatioRef.current === 1) {
      resizeAndPositionLayers()
    } else {
      resizeAndPositionLayers(true)
    }
  }

  useEffect(() => {
    if (!layoutState.isOverlayOpened) return

    resizeAndPositionLayers()

    if (isMagnifierActiveRef.current) {
      handleMagnifier()
    }
  }, [orderState.overlayImgIndex])

  const resizeLayersOnWindowResize = debounce(() => resizeAndPositionLayers())

  useEffect(() => {
    if (!layoutState.isOverlayOpened) {
      return () => {
      }
    }

    window.addEventListener('resize', resizeLayersOnWindowResize)

    overlayImageWrapRef.current.addEventListener('wheel', onMouseWheel)

    getDetails()

    dragEventRef.current = dragElement(overlayImageWrapRef.current, isSpaceRequired)
    overlayImageWrapRef.current.addEventListener('mousedown', dragEventRef.current)

    // cleanup: "remove event listeners" and "intervals" to avoid memory leaks by creating the same listeners
    return () => {
      window.removeEventListener('resize', resizeLayersOnWindowResize)
    }
  }, [layoutState.isOverlayOpened])

  const onContrastChange = ({ min, max }) => {
    if (min === minContrast && max === maxContrast) {
      overlayImgContrastRef.current.style.display = 'none'
    } else {
      overlayImgContrastRef.current.style.display = 'inline-block'
    }

    if (!originalImageDataRef.current) return // Ensure original data is available

    const adjust = (value, low, high) => {
      const range = high - low
      return Math.max(
        minContrast,
        Math.min(
          maxContrast,
          ((value - low) * maxContrast) / range,
        ),
      )
    }

    const imageData = contrastCtxRef.current.createImageData(originalImageDataRef.current)

    for (let i = 0; i < originalImageDataRef.current.data.length; i += 4) {
      imageData.data[i] = adjust(originalImageDataRef.current.data[i], min, max) // Red
      imageData.data[i + 1] = adjust(
        originalImageDataRef.current.data[i + 1],
        min,
        max,
      ) // Green
      imageData.data[i + 2] = adjust(
        originalImageDataRef.current.data[i + 2],
        min,
        max,
      ) // Blue
      imageData.data[i + 3] = originalImageDataRef.current.data[i + 3] // Alpha
    }

    // Draw the modified image data to the canvas
    contrastCtxRef.current.putImageData(imageData, 0, 0)
  }

  return (
    <>
      <div
        id="image-overlay"
        className="image-overlay"
        data-is-overlay="1"
        data-allowed-to-comment="1"
        data-is-shapes="1"
        style={(layoutState.isOverlayOpened) ? {} : { display: 'none' }}
      >
        <div
          className="image-overlay--left"
          style={{
            display: showInterface ? 'flex' : 'none',
          }}
        >
          <div className="image-overlay--left__top scrollbar-overflow">
            <div
              className={`image-overlay--left__title ${isExpanded.imageInformation ? 'expanded' : ''}`}
              onClick={() => expand('imageInformation')}
            >
              <div className="image-overlay__title-text">
                {Translation.image_information}
                <OverlayLeftArrowIconSvg
                  className={`title-icon 
                  ${isExpanded.imageInformation ? 'expanded' : ''}
                  ${(!isExpanded.imageInformation && (showFileName() || showImageDimensions()
                    || showColorSpace() || showBackgroundColor())) ? 'notice' : ''}`}
                />
              </div>
            </div>

            {
              (isExpanded.imageInformation || showFileName() || showImageDimensions()
                || showColorSpace() || showBackgroundColor()) && (
                <div
                  className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small word-break"
                >
                  {isExpanded.imageInformation && (
                    <Typography
                      label={
                        `${orderState.images[orderState.overlayImgIndex].original_image_id
                          ? orderState.images[orderState.overlayImgIndex].original_image_id
                          : orderState.images[orderState.overlayImgIndex].id}`
                      }
                    />
                  )}

                  {showFileName() && fileName()}

                  {showImageDimensions() && imageDimensions()}

                  {showColorSpace() && colorSpace()}

                  {showBackgroundColor() && backgroundColor()}

                  {isExpanded.imageInformation && (
                    <Typography
                      label={
                        `${Translation.colour_depth}: 
                        ${isOriginalImage
                          ? originalImageDetails?.colour_depth
                          : orderState.images[orderState.overlayImgIndex].colour_depth}
                        ${Translation.bits}`
                      }
                    />
                  )}
                </div>
              )
            }

            <div
              className={`image-overlay--left__title ${isExpanded.contrast ? 'expanded' : ''}`}
              onClick={() => expand('contrast')}
            >
              <div className="image-overlay__title-text">
                {Translation.contrast}
                <OverlayLeftArrowIconSvg
                  className={`title-icon ${isExpanded.contrast ? 'expanded' : 'title-icon'}`}
                />
              </div>
            </div>

            {isExpanded.contrast && (
              <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
                <DualRange
                  max={maxContrast}
                  min={minContrast}
                  values={contrastValue}
                  onChange={onContrastChange}
                  leftLabel={Translation.shadows}
                  rightLabel={Translation.lights}
                />
              </div>
            )}

            <div
              className={`image-overlay--left__title ${isExpanded.background ? 'expanded' : ''}`}
              onClick={() => expand('background')}
            >
              <div className="image-overlay__title-text">
                {Translation.background}
                <OverlayLeftArrowIconSvg
                  className={`title-icon ${isExpanded.background ? 'expanded' : 'title-icon'}`}
                />
              </div>
            </div>

            {isExpanded.background && (
              <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
                <Background />
              </div>
            )}

            <div
              className={`image-overlay--left__title ${isExpanded.grids ? 'expanded' : ''}`}
              onClick={() => expand('grids')}
            >
              <div className="image-overlay__title-text">
                {Translation.grids}
                <OverlayLeftArrowIconSvg
                  className={`title-icon ${isExpanded.grids ? 'expanded' : 'title-icon'}`}
                />
              </div>
            </div>

            {isExpanded.grids && (
              <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
                <div className="grid-list-container">
                  {orderState.gallery?.grids?.length > 0 && (
                    orderState.gallery?.grids.map((grid, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="grid-list-item" key={index}>
                        <div
                          className="grid-item-color"
                          style={{
                            backgroundColor:
                              orderState.selectedGrids.some((selectedGrid) => selectedGrid.name === grid.name)
                                ? getGridColor(index)
                                : null,
                          }}
                        />
                        <Checkbox
                          label={
                            (
                              <span className="overflow-ellipsis">
                                {grid.name || grid.name_short}
                                {' '}
                                {grid.template_name && (
                                  <span className="grid-item-template-name">{grid.template_name}</span>
                                )}
                              </span>
                            )
                          }
                          id={`grid-overlay-${orderState.gallery?.grids?.findIndex((g) => g.name === grid.name)}`}
                          checked={orderState.selectedGrids.some((selectedGrid) => selectedGrid.name === grid.name)}
                          onChange={() => onSelectGrid(grid)}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}

            {Object.keys(orderState.images[orderState.overlayImgIndex].path).length > 0 && (
              <>
                <div
                  className={`image-overlay--left__title ${isExpanded.paths ? 'expanded' : ''}`}
                  onClick={() => expand('paths')}
                >
                  <div className="image-overlay__title-text">
                    {Translation.paths}
                    <OverlayLeftArrowIconSvg
                      className={`title-icon ${isExpanded.paths ? 'expanded' : 'title-icon'}`}
                    />
                  </div>
                </div>

                {isExpanded.paths && (
                  <div
                    className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small"
                    style={{ ...(openColorPicker && { minHeight: `${PX_TO_REM['140']}rem` }) }}
                  >
                    <Typography
                      fontSize={PX_TO_REM['12']}
                      label={Translation.path_width}
                    />
                    <Range
                      value={orderState.pathRange}
                      min={PATH_WIDTH_MIN}
                      max={PATH_WIDTH_MAX}
                      showLabels
                      onChange={(e) => onChangePathRange(e.target.value)}
                    />

                    <div className="path-list">
                      {
                        Object.keys(imagePaths).map((path, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div className="path-item" key={index}>
                            <div
                              className="path-color"
                              style={{
                                backgroundColor:
                                  orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex].id]?.[path]
                                    ? layoutState.pathsColors[path]
                                    : null,
                              }}
                              onClick={() => handlePathColorClick(path)}
                            >
                              {openColorPicker && colorSelectingPath === path && (
                                <ColorPicker
                                  onColorChange={handlePathColorSelect}
                                  closePicker={closePicker}
                                  colors={PATH_COLORS}
                                />
                              )}
                            </div>
                            <Checkbox
                              label={path}
                              checked={
                                orderState.selectedImagePaths?.[orderState.images[orderState.overlayImgIndex].id]?.[path]
                              }
                              id={path}
                              onChange={() => onSelectPath(path)}
                            />
                            {
                              imagePaths[path]?.line.viewbox && <PathOutsideImageIconSvg />
                            }
                          </div>
                        ))
                      }

                    </div>
                  </div>
                )}
              </>
            )}

            {orderState.images[orderState.overlayImgIndex]?.layer?.length > 0 && (
              <>
                <div
                  className={`image-overlay--left__title ${isExpanded.layers ? 'expanded' : ''}`}
                  onClick={() => expand('layers')}
                >
                  <div className="image-overlay__title-text">
                    {Translation.layers}
                    <OverlayLeftArrowIconSvg
                      className={`title-icon ${isExpanded.layers ? 'expanded' : 'title-icon'}`}
                    />
                  </div>
                </div>

                {isExpanded.layers && (
                  <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
                    <div className="layers-container">
                      <RadioGroup
                        values={layerValues}
                        onChange={onHandleLayerChange}
                        value={
                          orderState.selectedLayers[orderState.images[orderState.overlayImgIndex].id]
                          || `${ALL_LAYERS_KEY}-${orderState.images[orderState.overlayImgIndex].id}`
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            <div
              className={`image-overlay--left__title ${isExpanded.alphaChannel ? 'expanded' : ''}`}
              onClick={() => expand('alphaChannel')}
            >
              <div className="image-overlay__title-text">
                {Translation.alpha_channel}
                <OverlayLeftArrowIconSvg
                  className={`title-icon ${isExpanded.alphaChannel ? 'expanded' : 'title-icon'}`}
                />
              </div>
            </div>

            {isExpanded.alphaChannel && (
              <div className="image-overlay--left__content scrollbar-overflow scrollbar-overflow__small">
                <div className="alpha-channel-list">
                  <div className="alpha-channel-item">
                    <Toggle
                      checked={false}
                      onChange={() => {
                      }}
                      id="alpha-chanel-1"
                    />
                    <LayerMaskIconSvg />
                    <Typography label="Layer name" />
                  </div>
                  <div className="alpha-channel-item">
                    <Toggle
                      checked
                      onChange={() => {
                      }}
                      id="alpha-chanel-2"
                    />
                    <LayerMaskIconSvg />
                    <Typography label="Layer name" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className="image-overlay--middle"
          style={{ backgroundColor: orderState.galleryBackground }}
          ref={overlayContainerRef}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {userState.user.is_admin && orderState?.orderViewingUsers?.length ? (
            showInterface ? <OverlayAvatars /> : null
          ) : null}
          <div
            ref={overlayImageWrapRef}
            id={OVERLAY_WRAP_CLASS}
            className={OVERLAY_WRAP_CLASS}
            style={{
              width: `${overlayImgSize.width}px`,
              height: `${overlayImgSize.height}px`,
              ...(isBroken && {
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              }),
              ...(orderState.previewsBackground === TRANSPARENT_KEY
                ? { backgroundImage: `url(${TRANSPARENT_BG})` }
                : { backgroundColor: orderState.previewsBackground }),
            }}
          >
            <img
              id={OVERLAY_IMG_CLASS}
              ref={overlayImgRef}
              src={
                // eslint-disable-next-line no-nested-ternary
                isOriginalImage
                  ? imageElements?.[orderState.images[orderState.overlayImgIndex]?.original_image_id]?.src
                  // eslint-disable-next-line no-nested-ternary
                  : (imageElements?.[orderState.images[orderState.overlayImgIndex]?.id]?.src
                    ? ((orderState.selectedLayers[orderState.images[orderState.overlayImgIndex]?.id]
                      === `${ALL_LAYERS_KEY}-${orderState.images[orderState.overlayImgIndex]?.id}`
                      || !orderState.selectedLayers[orderState.images[orderState.overlayImgIndex]?.id])
                      ? imageElements?.[orderState.images[orderState.overlayImgIndex]?.id]?.src
                      : orderState.layerUrls?.[orderState.images[orderState.overlayImgIndex].id
                      ]?.[orderState.selectedLayers[orderState.images[orderState.overlayImgIndex]?.id]])
                    : EMPTY_IMG)
              }
              onLoad={handleOverlayImgLoad}
              onError={handleOverlayImgLoadError}
              className={`${OVERLAY_IMG_CLASS} ${SKELETON_CLASS} ${NOSELECT_CLASS}`}
              style={{
                width: `${overlayImgSize.width}px`,
                height: `${overlayImgSize.height}px`,
                ...(isBroken && {
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'transparent',
                }),
                ...(isNotFound && {
                  backgroundColor: 'transparent',
                }),
              }}
              alt=""
            />

            <svg
              width={orderState.images[orderState.overlayImgIndex]?.width || 0}
              height={orderState.images[orderState.overlayImgIndex]?.height || 0}
              viewBox={`0 0 
                ${orderState.images[orderState.overlayImgIndex]?.width || 0} 
                ${orderState.images[orderState.overlayImgIndex]?.height || 0}`}
              className={OVERLAY_GUIDE_CLASS}
              style={{
                width: `${overlayImgSize.width}px`,
                height: `${overlayImgSize.height}px`,
              }}
            />

            <svg
              id={OVERLAY_DRAWING_CLASS}
              className={OVERLAY_DRAWING_CLASS}
              ref={overlayImgDrawingRef}
              width={orderState.images[orderState.overlayImgIndex]?.width || 0}
              height={orderState.images[orderState.overlayImgIndex]?.height || 0}
              viewBox={`0 0 
                ${orderState.images[orderState.overlayImgIndex]?.width || 0} 
                ${orderState.images[orderState.overlayImgIndex]?.height || 0}`}
              style={{
                width: `${overlayImgSize.width}px`,
                height: `${overlayImgSize.height}px`,
              }}
            />

            <canvas
              id={OVERLAY_CONTRAST_CLASS}
              ref={overlayImgContrastRef}
              width={overlayImgSize.width}
              height={overlayImgSize.height}
              style={{
                display: 'none',
                width: `${overlayImgSize.width}px`,
                height: `${overlayImgSize.height}px`,
              }}
            />

            {orderState.selectedUnderlayUrl && (
              <img
                id={OVERLAY_UNDERLAY_CLASS}
                className={OVERLAY_UNDERLAY_CLASS}
                src={orderState.selectedUnderlayUrl}
                style={underlayStyle}
                alt=""
              />
            )}
          </div>
        </div>

        <div
          className="image-overlay--right"
          style={{
            display: showInterface ? 'flex' : 'none',
          }}
        >
          <div className="image-overlay--navigation">
            <div className="image-overlay--navigation__wrap">
              {orderState.imageType !== IMAGE_TYPES.input && (
                <div
                  id="image-overlay__compare"
                  className={`image-overlay__control image-overlay__compare 
                    ${NOSELECT_CLASS} ${isOriginalImage && ACTIVE_CLASS}`}
                  onClick={toggleOriginalImage}
                >
                  <OverlayCompareIconSvg />
                </div>
              )}
              <div
                id="image-overlay__zoom"
                onClick={handleOverlayZoom}
                className={`image-overlay__control image-overlay__zoom ${NOSELECT_CLASS}`}
              >
                {zoomValue}
                %
              </div>
            </div>

            <div className="image-overlay--navigation__wrap">
              <div
                id="image-overlay__drawing"
                className={`image-overlay__control image-overlay__drawing 
                 ${orderState.drawingType && ACTIVE_CLASS}
                 ${NOSELECT_CLASS}`}
                onClick={handleOverlayDrawingClick}
              >
                <OverlayDrawingIconSvg />
              </div>

              <div
                id="image-overlay__drag"
                className={`image-overlay__control image-overlay__drag 
                  ${NOSELECT_CLASS} ${!isSpaceRequired && ACTIVE_CLASS}`}
                onClick={toggleSpaceRequired}
              >
                <OverlayDragIconSvg />
              </div>

              <div
                id="image-overlay__magnify"
                className={`image-overlay__control image-overlay__magnify 
                  ${NOSELECT_CLASS} 
                  ${isMagnifierActive && ACTIVE_CLASS}`}
                onClick={handleMagnifier}
              >
                <OverlayMagnifyIconSvg />
              </div>
            </div>

            <div className="image-overlay--navigation__wrap">
              <div
                id="image-overlay__prev"
                onClick={handleNextPrevClick}
                data-direction="-1"
                className={`image-overlay__control image-overlay__prev 
                  ${(orderState.overlayImgIndex === 0) && DISABLED_CLASS} 
                  ${NOSELECT_CLASS}`}
              >
                <OverlayPrevIconSvg />
              </div>

              <div
                id="image-overlay__next"
                onClick={handleNextPrevClick}
                data-direction="1"
                className={`image-overlay__control image-overlay__next 
                  ${(orderState.overlayImgIndex === (orderState.images?.length || 0) - 1) && DISABLED_CLASS} 
                  ${NOSELECT_CLASS}`}
              >
                <OverlayNextIconSvg />
              </div>
            </div>

            <div className="image-overlay--images-counter">
              <span id="image-overlay--images-counter__current">{(orderState.overlayImgIndex + 1)}</span>
              <span className="image-overlay--images-counter__separator">/</span>
              <span id="image-overlay--images-counter__total">{orderState.images?.length}</span>
            </div>
          </div>
          <div className="image-overlay--right__top scrollbar-overflow">
            <div
              className={`image-overlay--left__title ${isExpanded.history ? 'expanded' : ''}`}
              onClick={() => expand('history')}
            >
              <div className="image-overlay__title-text">
                {Translation.history}
                <OverlayLeftArrowIconSvg
                  className={`title-icon ${isExpanded.history ? 'expanded' : 'title-icon'}`}
                />
              </div>
            </div>

            {isExpanded.history
              && (orderState.images[orderState.overlayImgIndex]
                && orderState.images[orderState.overlayImgIndex].image_comments.length > 0) && (
                <div className="scrollbar-overflow scrollbar-overflow__small">
                  {orderState.images[orderState.overlayImgIndex].image_comments.map((imageComment) => (
                    <HistoryOverlay key={imageComment.id} comment={imageComment} />
                  ))}
                </div>
            )}

            {orderState.gallery?.is_allowed_to_comment === 1 && (
              <>
                <div
                  className={`image-overlay--left__title ${isExpanded.commentSuggestion ? 'expanded' : ''}`}
                  onClick={() => expand('commentSuggestion')}
                >
                  <div className="image-overlay__title-text">
                    {Translation.comment_suggestions}
                    <OverlayLeftArrowIconSvg
                      className={`title-icon ${isExpanded.commentSuggestion ? 'expanded' : 'title-icon'}`}
                    />
                  </div>
                </div>

                {isExpanded.commentSuggestion
                  && (orderState.images[orderState.overlayImgIndex]
                    && orderState.images[orderState.overlayImgIndex].image_comments.length > 0) && (
                    <div className="scrollbar-overflow scrollbar-overflow__small">
                      {orderState.images[orderState.overlayImgIndex].image_comments.map((imageComment) => (
                        <CommentSuggestionOverlay key={imageComment.id} comment={imageComment} />
                      ))}
                    </div>
                )}

                {(orderState.images[orderState.overlayImgIndex]
                  && orderState.images[orderState.overlayImgIndex].image_comments.length > 0) && (
                  <>
                    <div className="image-overlay--left__title image-overlay--left__title--fixed">
                      <div className="image-overlay__title-text">
                        {Translation.active_comments}
                      </div>
                    </div>

                    {orderState.images[orderState.overlayImgIndex].image_comments.map((imageComment) => (
                      <CommentOverlay key={imageComment.id} comment={imageComment} />
                    ))}
                  </>
                )}
              </>
            )}
          </div>

          {orderState.gallery?.is_allowed_to_comment === 1 && (
            <Comment
              refreshGallery={refreshGallery}
              resetInterval={resetInterval}
              resizeRatioRef={resizeRatioRef}
              overlayImgDrawingRef={overlayImgDrawingRef}
              isSpaceRequired={isSpaceRequired}
            />
          )}
        </div>

        <div onClick={onCloseOverlay} id="image-overlay__close" className="image-overlay__close">
          <OverlayCloseIconSvg />
        </div>

        {layoutState.isShortcutsOpened && (
          <Shortcuts />
        )}

        <div className={`image-overlay--left__bottom ${!showInterface ? 'hidden' : ''}`}>
          <div className="image-overlay--left__buttons">
            <div
              className={`image-overlay--left__button ${showInterface && ACTIVE_CLASS}`}
              onClick={() => toggleInterface()}
            >
              <CategoryIconSvg />
              {Translation.interface}
            </div>
            <div
              className={`image-overlay--left__button ${layoutState.isShortcutsOpened && ACTIVE_CLASS}`}
              onClick={() => toggleShortcuts()}
            >
              <RocketIconSvg />
              {Translation.shortcuts}
            </div>
            <div
              className={
                `image-overlay--left__button 
                ${orderState.gallery?.is_send_into_redo ? '' : DISABLED_CLASS}`
              }
              onClick={() => sendInRedo()}
            >
              <RedoIconSvg />
              {Translation.redo}
            </div>
          </div>
        </div>

        {!showInterface && (
          <>
            {orderState.gallery?.is_allowed_to_comment === 1 && (
              <CollapsibleCommentOverlay
                image={orderState.images[orderState.overlayImgIndex]}
                refreshGallery={refreshGallery}
                resetInterval={resetInterval}
                resizeRatioRef={resizeRatioRef}
                overlayImgDrawingRef={overlayImgDrawingRef}
                isSpaceRequired={isSpaceRequired}
              />
            )}

            <CollapsibleImageInfo
              image={orderState.images[orderState.overlayImgIndex]}
              refreshGallery={refreshGallery}
              resetInterval={resetInterval}
              isOriginalImage={isOriginalImage}
              originalImageDetails={originalImageDetails}
            />
          </>
        )}
      </div>

      <div
        id="magnifier-overlay"
        className="magnifier-overlay"
        ref={magnifierOverlayRef}
      />
    </>
  )
}

Overlay.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Overlay.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default Overlay
