import React from 'react'
import PropTypes from 'prop-types'

import { prepareComment } from '../../../../helpers/Utils'

import './index.scss'

const CommentSuggestionOverlay = ({ comment }) => (
  <div className="overlay--comment-suggestion">
    {prepareComment(comment.comment)}
  </div>
)

CommentSuggestionOverlay.propTypes = {
  comment: PropTypes.instanceOf(Object),
}

CommentSuggestionOverlay.defaultProps = {
  comment: {},
}

export default CommentSuggestionOverlay
