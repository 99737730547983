import React from 'react'
import PropTypes from 'prop-types'

import { CENTER_TOP } from '../../helpers/Constants'

import { ReactComponent as CloseSvg } from '../../svg/overlay_close_icon.svg'

import './index.scss'

export const getSizeClass = (size = '') => {
  if (size === 'small') return 'modal-small'
  if (size === 'medium') return 'modal-medium'
  if (size === 'large') return 'modal-large'
  if (size === 'xl') return 'modal-xl'
  return ''
}

const getClassName = (size) => `modal ${getSizeClass(size)}`

const Modal = ({
  id,
  size,
  isShown,
  hasCloseIcon,
  top,
  left,
  onClickCloseIcon,
  onClickOutside,
  children,
}) => (
  <div
    className="modal--wrap"
    onClick={onClickOutside}
    style={{ display: isShown ? '' : 'none' }}
  >
    <div
      id={id}
      className={getClassName(size)}
      style={{ top, left }}
      onClick={(e) => e.stopPropagation()}
    >
      <div>
        {children}
      </div>

      {
        (hasCloseIcon)
          ? (
            <div className="modal--close-icon" onClick={onClickCloseIcon}>
              <CloseSvg />
            </div>
          ) : null
      }
    </div>
  </div>
)

Modal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['', 'small', 'medium', 'large', 'xl']),
  isShown: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hasCloseIcon: PropTypes.bool,
  top: PropTypes.string,
  left: PropTypes.string,
  onClickCloseIcon: PropTypes.func,
  onClickOutside: PropTypes.func,
}

Modal.defaultProps = {
  id: '',
  size: '',
  isShown: false,
  hasCloseIcon: true,
  top: CENTER_TOP,
  left: '50%',
  onClickCloseIcon: () => {
  },
  onClickOutside: () => {
  },
}

export default Modal
