import React from 'react'
import { useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'
import {
  DEFAULT_IMAGE_SIZES,
  IMAGE_TYPES,
  SKELETON_CLASS,
} from '../../../helpers/Constants'
import { MY_DOOPIC_URLS } from '../../../helpers/Urls'

import Preview from './Preview'

import { ReactComponent as ScissorSvg } from '../../../svg/scissor.svg'
import { ReactComponent as MagnifierSvg } from '../../../svg/magnifier.svg'

import './index.scss'

const Previews = ({ refreshGallery, resetInterval }) => {
  const orderState = useStoreState((state) => ({
    filteredImages: state.order.filteredImages,
    imageType: state.order.imageType,
    isImagesLoading: state.order.isImagesLoading,
  }))

  return (
    // eslint-disable-next-line no-nested-ternary
    orderState.filteredImages ? (
      orderState.filteredImages.length ? (
        <>
          {orderState.filteredImages.map((image, index) => (
            <Preview
              key={image.id}
              image={image}
              index={index}
              refreshGallery={refreshGallery}
              resetInterval={resetInterval}
            />
          ))}
        </>
      ) : (
        <div className="empty-result">
          <ScissorSvg />
          {orderState.imageType === IMAGE_TYPES.input
            ? (
              <h6 className="h6">
                {Translation.sorry_no_images_found}
              </h6>
            )
            : (
              <h6 className="h6">
                {Translation.no_output_images_found_1}
                <br />
                {Translation.no_output_images_found_2}
              </h6>
            )}
        </div>
      )
    ) : (
      orderState.isImagesLoading ? (
        <>
          {[...Array(12)].map((x, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className="gallery-image--wrap"
              style={{ width: `${DEFAULT_IMAGE_SIZES.width}px`, height: '400px' }}
            >
              <div
                className={`image-preview--bg ${SKELETON_CLASS}`}
                style={{ width: `${DEFAULT_IMAGE_SIZES.width}px`, height: `${DEFAULT_IMAGE_SIZES.height}px` }}
              />

              <div className="image-preview--details">
                <div className="image-preview--details__filename">
                  <span className="image-preview--details__filename--filename">
                    <div
                      className={SKELETON_CLASS}
                      style={{
                        width: '100px', height: '10px', marginBottom: '10px', marginTop: '5px',
                      }}
                    />
                  </span>
                </div>

                <div className="image-preview--details__sizes--wrap">
                  <div className="image-preview--details__sizes">
                    <div className={SKELETON_CLASS} style={{ width: '150px', height: '10px', marginBottom: '10px' }} />
                  </div>

                  <div className="image-preview--details__colour-space">
                    <div className={SKELETON_CLASS} style={{ width: '140px', height: '10px', marginBottom: '10px' }} />
                  </div>

                  <div className="image-preview--details__bg-colour">
                    <div className={SKELETON_CLASS} style={{ width: '120px', height: '10px', marginBottom: '10px' }} />
                  </div>

                  <div className="image-preview--details__colour-depth">
                    <div className={SKELETON_CLASS} style={{ width: '110px', height: '10px', marginBottom: '10px' }} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="empty-result">
          <MagnifierSvg />
          <h6 className="h6">
            {Translation.we_could_not_find_order}
            {' '}
            <a href={MY_DOOPIC_URLS.support} target="_blank" rel="noreferrer">{Translation.support}</a>
            {' '}
            {Translation.for_assistance}
            .
          </h6>
        </div>
      )
    )
  )
}

Previews.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Previews.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default Previews
