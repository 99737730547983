import React, { useEffect, useRef, useState } from 'react'
import { useStoreState } from 'easy-peasy'

import { dragElement } from '../../../../helpers/Utils'
import { Translation } from '../../../../helpers/Translation'
import { ACTIVE_CLASS } from '../../../../helpers/Constants'

import './index.scss'

const Shortcuts = () => {
  const layoutState = useStoreState((state) => ({
    isShortcutsOpened: state.layout.isShortcutsOpened,
  }))

  const [isFullview, setIsFullview] = useState(true)
  const shortcutsRef = useRef(null)
  const dragEventRef = useRef(null)

  useEffect(() => {
    if (layoutState.isShortcutsOpened) {
      dragEventRef.current = dragElement(shortcutsRef.current)
      shortcutsRef.current.addEventListener('mousedown', dragEventRef.current)
    }

    return () => {
      shortcutsRef.current?.removeEventListener('mousedown', dragEventRef.current)
    }
  }, [layoutState.isShortcutsOpened])

  const SHORTCUTS = {
    fullView: [
      {
        title: Translation.next_image,
        shortcuts: ['D', ''],
        bind: Translation.or,
      },
      {
        title: Translation.previous_image,
        shortcuts: ['A', ''],
        bind: Translation.or,
      },
      {
        title: Translation.grayscale_image,
        shortcuts: ['G'],
      },
      {
        title: Translation.view_full_size,
        shortcuts: ['H'],
      },
      {
        title: Translation.go_to_next_layer,
        shortcuts: ['L'],
      },
      {
        title: Translation.go_to_previous_layer,
        shortcuts: ['K'],
      },
      {
        title: Translation.show_hide_magnifier,
        shortcuts: ['M'],
      },
      {
        title: Translation.show_next_path,
        shortcuts: ['P'],
      },
      {
        title: Translation.show_previous_path,
        shortcuts: ['O'],
      },
      {
        title: Translation.show_hide_image_versions,
        shortcuts: ['V'],
      },
      {
        title: Translation.history,
        shortcuts: ['Control', 'Control'],
        bind: '+',
      },
      {
        title: Translation.undo_drawings,
        shortcuts: ['Shift', 'L'],
        bind: '+',
      },
      {
        title: Translation.show_image_levels_controls,
        shortcuts: ['Control', 'Control'],
        bind: '+',
      },
    ],
    smallView: [
      {
        title: Translation.load_input_image_on_hover_output,
        shortcuts: ['C'],
      },
      {
        title: Translation.show_hide_image_red_filter,
        shortcuts: ['R'],
      },
      {
        title: Translation.select_multiple_images,
        shortcuts: ['S'],
        bind: '+',
      },
      {
        title: Translation.select_all_images,
        shortcuts: ['Shift', 'A'],
        bind: '+',
      },
      {
        title: Translation.show_next_path,
        shortcuts: ['P'],
      },
      {
        title: Translation.show_previous_path,
        shortcuts: ['O'],
      },
      {
        title: Translation.go_to_next_layer,
        shortcuts: ['L'],
      },
      {
        title: Translation.go_to_previous_layer,
        shortcuts: ['K'],
      },
      {
        title: Translation.select_previous_filtered_comment,
        shortcuts: ['-'],
      },
      {
        title: Translation.select_next_filtered_comment,
        shortcuts: ['-'],
      },
      {
        title: Translation.paste_filtered_comment,
        shortcuts: ['Enter'],
      },
    ],
  }

  return (
    <div ref={shortcutsRef} className="shortcuts-container">
      <div className="top">
        <div
          className={`shortcut-btn ${isFullview && ACTIVE_CLASS}`}
          onClick={() => setIsFullview(true)}
        >
          {Translation.full_view}
        </div>
        <div
          className={`shortcut-btn ${!isFullview && ACTIVE_CLASS}`}
          onClick={() => setIsFullview(false)}
        >
          {Translation.small_view}
        </div>
      </div>
      <div className="bottom">
        {(isFullview ? SHORTCUTS.fullView : SHORTCUTS.smallView).map((shortcut, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="row">
            <div className="left">
              {shortcut.title}
            </div>
            <div className="right">
              {shortcut.shortcuts.map((key, keyIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={keyIndex} className="shortcuts">
                  <div className="shortcut">
                    {key}
                  </div>
                  {keyIndex === 0 && shortcut.bind && (
                    <div className="or">
                      {shortcut.bind}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Shortcuts
