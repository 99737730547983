import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import { API_ENDPOINTS_GALLERY } from '../../../helpers/Urls'
import { getEndpointUrl, useEventListener } from '../../../helpers/Utils'

import Typography from '../../../components/Typography'
import Dialog from '../../../components/Dialog'

import './index.scss'

const Dialogs = ({ refreshGallery, resetInterval }) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isDialogModalOpened: state.layout.isDialogModalOpened,
    dialogModalState: state.layout.dialogModalState,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateDialogModalOpened: actions.layout.updateDialogModalOpened,
  }))

  const orderState = useStoreState((state) => ({
    imagesToBeDeleted: state.order.imagesToBeDeleted,
    commentsToBeDeleted: state.order.commentsToBeDeleted,
    selectedImages: state.order.selectedImages,
    imageToBeAllowedOverwrite: state.order.imageToBeAllowedOverwrite,
    gallery: state.order.gallery,
  }))

  const orderActions = useStoreActions((actions) => ({
    imageAction: actions.order.imageAction,
    addComment: actions.order.addComment,
    clearImages: actions.order.clearImages,
    setImageToBeAllowedOverwrite: actions.order.setImageToBeAllowedOverwrite,
  }))

  const commentTextareaRef = useRef(null)

  const onDeleteImages = async () => {
    if (orderState.imagesToBeDeleted?.length) {
      const res = await orderActions.imageAction({
        url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.delete),
        body: {
          image_ids: orderState.imagesToBeDeleted,
        },
      })

      if (res.success) {
        refreshGallery()
        resetInterval()
        layoutActions.updateDialogModalOpened(false)
      }
    }
  }

  const onDeleteComments = async () => {
    if (orderState.commentsToBeDeleted?.length) {
      const res = await orderActions.imageAction({
        url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.commentDelete),
        body: {
          image_ids: orderState.commentsToBeDeleted,
        },
      })

      if (res.success) {
        refreshGallery()
        resetInterval()
        layoutActions.updateDialogModalOpened(false)
      }
    }
  }

  const onAddComment = async () => {
    if (orderState.gallery?.is_allowed_to_comment !== 1) return
    if (!commentTextareaRef?.current?.value) return

    const res = await orderActions.addComment({
      is_admin: userState.user.is_admin,
      body: {
        image_ids: orderState.selectedImages,
        comment: commentTextareaRef?.current?.value,
        is_reply: false,
      },
    })

    if (res) {
      refreshGallery()
      resetInterval()
      layoutActions.updateDialogModalOpened(false)
    }
  }

  const onCommentSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onAddComment().then(() => {
      })
    }
  }

  const onClearUploads = async () => {
    const res = await orderActions.clearImages({
      is_admin: userState.user.is_admin,
      body: {
        image_ids: [orderState.imageToBeAllowedOverwrite],
      },
    })

    if (res) {
      refreshGallery()
      resetInterval()
      layoutActions.updateDialogModalOpened(false)
      orderActions.setImageToBeAllowedOverwrite(null)
    }
  }

  const [dialogProps, setDialogProps] = useState({
    onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
    onClickOutside: () => layoutActions.updateDialogModalOpened(false),
    onClickCancel: () => layoutActions.updateDialogModalOpened(false),
    title: Translation.delete_image,
    yesLabel: Translation.ok,
    cancelLabel: Translation.cancel,
    onClickYes: onDeleteImages,
    content: (
      <Typography
        variant="xs"
        label={Translation.sure_to_delete_image}
      />
    ),
  })

  useEffect(() => {
    if (layoutState.isDialogModalOpened) {
      switch (layoutState.dialogModalState) {
        case 0:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: Translation.delete_image,
            yesLabel: Translation.ok,
            cancelLabel: Translation.cancel,
            onClickYes: onDeleteImages,
            content: (
              <Typography
                variant="xs"
                label={Translation.sure_to_delete_image}
              />
            ),
          })
          break
        case 1:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: Translation.delete_comments,
            yesLabel: Translation.ok,
            cancelLabel: Translation.cancel,
            onClickYes: onDeleteComments,
            content: (
              <Typography
                variant="xs"
                label={Translation.sure_to_delete_comments}
              />
            ),
          })
          break
        case 2:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: Translation.add_comment_selected_images,
            yesLabel: Translation.add,
            cancelLabel: Translation.cancel,
            onClickYes: () => onAddComment(),
            content: (
              <textarea
                className="comment-textarea"
                placeholder={`${Translation.leave_your_comment}...`}
                ref={commentTextareaRef}
                onKeyDown={onCommentSubmit}
              />
            ),
          })
          break
        case 3:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: `${Translation.attention}!`,
            yesLabel: Translation.ok,
            cancelLabel: Translation.cancel,
            onClickYes: onClearUploads,
            content: (
              <>
                <Typography
                  variant="xs"
                  label={Translation.sp_overwriting_dialog_title}
                />
                <br />
                <Typography
                  variant="xs"
                  label={Translation.the_image_has_comments}
                />
                <Typography
                  variant="xs"
                  label={Translation.you_get_images_from_sp_again}
                />
                <Typography
                  variant="xs"
                  label={Translation.there_is_a_newer_version_to_this_image}
                />
              </>
            ),
          })
          break
        default:
          setDialogProps({
            onClickCloseIcon: () => layoutActions.updateDialogModalOpened(false),
            onClickOutside: () => layoutActions.updateDialogModalOpened(false),
            onClickCancel: () => layoutActions.updateDialogModalOpened(false),
            title: Translation.delete_image,
            yesLabel: Translation.ok,
            cancelLabel: Translation.cancel,
            onClickYes: onDeleteImages,
            content: (
              <Typography
                variant="xs"
                label={Translation.sure_to_delete_image}
              />
            ),
          })
          break
      }

      if (layoutState.dialogModalState === 2) {
        setTimeout(() => {
          commentTextareaRef?.current?.focus()
        }, 100)
      }
    }
  }, [])

  useEventListener('keyup', (e) => {
    if (e.key === 'Enter' && layoutState.isDialogModalOpened) {
      if (layoutState.dialogModalState === 0) {
        onDeleteImages().then(() => {
        })
      } else if (layoutState.dialogModalState === 1) {
        onDeleteComments().then(() => {
        })
      } else if (layoutState.dialogModalState === 3) {
        onClearUploads().then(() => {
        })
      }
    }
  })

  return (
    <Dialog
      isShown={layoutState.isDialogModalOpened}
      onClickCloseIcon={dialogProps.onClickCloseIcon}
      onClickOutside={dialogProps.onClickOutside}
      isCentered
      onClickCancel={dialogProps.onClickCancel}
      title={dialogProps.title}
      yesLabel={dialogProps.yesLabel}
      cancelLabel={dialogProps.cancelLabel}
      onClickYes={dialogProps.onClickYes}
      content={dialogProps.content}
    />
  )
}

Dialogs.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Dialogs.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default Dialogs
