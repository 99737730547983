import React, { useEffect } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import {
  ACTIVE_CLASS,
  DISABLED_CLASS,
} from '../../../helpers/Constants'
import { hasClass } from '../../../helpers/Utils'

import Tooltip from '../../../components/Tooltip'

import { ReactComponent as CounterMissingImagesSvg } from '../../../svg/counter_missing_images.svg'
import { ReactComponent as CounterSystemErrorsSvg } from '../../../svg/counter_system_errors.svg'
import { ReactComponent as CounterNotificationsSvg } from '../../../svg/counter_notifications.svg'
import { ReactComponent as CounterCommentsSvg } from '../../../svg/counter_comments.svg'
import { ReactComponent as CounterApprovedImagesSvg } from '../../../svg/counter_approved_images.svg'

import './index.scss'

const Stats = () => {
  const orderState = useStoreState((state) => ({
    imageFilterCounts: state.order.imageFilterCounts,
    imageFilters: state.order.imageFilters,
  }))

  const orderActions = useStoreActions((actions) => ({
    setImageFilters: actions.order.setImageFilters,
  }))

  const handleFilters = (e) => {
    if (hasClass(e.currentTarget, DISABLED_CLASS)) return

    const { filterType } = e.currentTarget.dataset

    const updatedFilters = {
      ...orderState.imageFilters,
      [filterType]: orderState.imageFilters[filterType] === 0 ? 1 : 0,
    }

    const isAllFiltersSetToOne = Object.values(updatedFilters).every((value) => value === 1)

    // If all filters are set to 1, invert all filters to 0
    if (isAllFiltersSetToOne) {
      Object.keys(updatedFilters).forEach((key) => {
        updatedFilters[key] = 0
      })
    }

    orderActions.setImageFilters(updatedFilters)
  }

  useEffect(() => {
    orderActions.setImageFilters(
      localStorage.getItem('imageFilters')
        ? JSON.parse(localStorage.getItem('imageFilters'))
        : orderState.imageFilters,
    )
  }, [])

  return (
    <div className="header--wraps header--counters__wrap">
      <div className="header--counters__count--wrap">
        <Tooltip
          position="bottom"
          text={Translation.missing_images}
        >
          <div
            className={`header--counter
              ${orderState.imageFilterCounts.is_missing === 0 && DISABLED_CLASS}
              ${orderState.imageFilters?.is_missing && ACTIVE_CLASS}`}
            data-filter-type="is_missing"
            onClick={handleFilters}
          >
            <div className="header--counters__icon">
              <CounterMissingImagesSvg className="top-2" />
            </div>
            <div
              className={`header--counters__value header--counters--is-missing
                ${orderState.imageFilterCounts.is_missing === 0 && DISABLED_CLASS}`}
            >
              {orderState.imageFilterCounts.is_missing}
            </div>
          </div>
        </Tooltip>

        <Tooltip
          position="bottom"
          text={Translation.system_errors}
        >
          <div
            className={`header--counter
              ${orderState.imageFilterCounts.is_errors === 0 && DISABLED_CLASS}
              ${orderState.imageFilters?.is_errors && ACTIVE_CLASS}`}
            data-filter-type="is_errors"
            onClick={handleFilters}
          >
            <div className="header--counters__icon">
              <CounterSystemErrorsSvg className="top-1" />
            </div>
            <div
              className={`header--counters__value header--counters--is-errors
                ${orderState.imageFilterCounts.is_errors === 0 && DISABLED_CLASS}`}
            >
              {orderState.imageFilterCounts.is_errors}
            </div>
          </div>
        </Tooltip>

        <Tooltip
          position="bottom"
          text={Translation.notifications}
        >
          <div
            className={`header--counter
              ${orderState.imageFilterCounts.is_notifications === 0 && DISABLED_CLASS}
              ${orderState.imageFilters?.is_notifications && ACTIVE_CLASS}`}
            data-filter-type="is_notifications"
            onClick={handleFilters}
          >
            <div className="header--counters__icon">
              <CounterNotificationsSvg className="top-1" />
            </div>
            <div
              className={`header--counters__value header--counters--is-notifications
                ${orderState.imageFilterCounts.is_notifications === 0 && DISABLED_CLASS}`}
            >
              {orderState.imageFilterCounts.is_notifications}
            </div>
          </div>
        </Tooltip>

        <Tooltip
          position="bottom"
          text={Translation.comments}
        >
          <div
            className={`header--counter
              ${orderState.imageFilterCounts.is_comments === 0 && DISABLED_CLASS}
              ${orderState.imageFilters?.is_comments && ACTIVE_CLASS}`}
            data-filter-type="is_comments"
            onClick={handleFilters}
          >
            <div className="header--counters__icon">
              <CounterCommentsSvg className="top-2" />
            </div>
            <div
              className={`header--counters__value header--counters--is-comments
                ${orderState.imageFilterCounts.is_comments === 0 && DISABLED_CLASS}`}
            >
              {orderState.imageFilterCounts.is_comments}
            </div>
          </div>
        </Tooltip>

        <Tooltip
          position="bottom"
          text={Translation.approved_images}
        >
          <div
            className={`header--counter
              ${orderState.imageFilterCounts.is_confirmed === 0 && DISABLED_CLASS}
              ${orderState.imageFilters?.is_confirmed && ACTIVE_CLASS}`}
            data-filter-type="is_confirmed"
            onClick={handleFilters}
          >
            <div className="header--counters__icon">
              <CounterApprovedImagesSvg className="top-1" />
            </div>
            <div
              className={`header--counters__value header--counters--is-confirmed
                ${orderState.imageFilterCounts.is_confirmed === 0 && DISABLED_CLASS}`}
            >
              {orderState.imageFilterCounts.is_confirmed}
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default Stats
