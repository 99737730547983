import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import {
  AVATAR_STYLE_LEFT,
  INTERVAL_1_SECOND,
  INTERVAL_60_SECONDS,
} from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'

import './index.scss'

const Avatars = () => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    orderId: state.order.orderId,
    imageType: state.order.imageType,
    orderViewingUsers: state.order.orderViewingUsers,
  }))

  const orderActions = useStoreActions((actions) => ({
    ping: actions.order.ping,
  }))

  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)
  const [isMouseOver, setIsMouseOver] = useState(false)

  const ping = async () => {
    orderActions.ping({
      is_admin: userState.user.is_admin,
      body: {
        order_id: orderState.orderId,
        image_type: orderState.imageType,
      },
    })
  }

  const isInFocus = useRef(false)
  const galleryPingInterval = useRef(0)

  const handleGalleryPing = () => {
    // reset ping interval
    if (galleryPingInterval.current) clearInterval(galleryPingInterval.current)

    // with 6 seconds delay make first request
    setTimeout(() => {
      if (isInFocus.current) {
        ping().then(() => {
        })
      }

      // send request every 1 minute
      galleryPingInterval.current = setInterval(() => {
        if (isInFocus.current) {
          ping().then(() => {
          })
        }
      }, INTERVAL_60_SECONDS)
    }, 6000)
  }

  useEffect(() => {
    if (!orderState.orderId) {
      return () => {
      }
    }
    if (Object.keys(userState.user).length === 0) {
      return () => {
      }
    }

    const hasFocusInterval = setInterval(() => {
      if (!isInFocus.current && document.hasFocus()) handleGalleryPing()

      isInFocus.current = document.hasFocus()
    }, INTERVAL_1_SECOND)

    // cleanup: "remove intervals" to avoid memory leaks by creating the same listeners
    return () => {
      clearInterval(hasFocusInterval)
    }
  }, [orderState.orderId, userState.user.is_admin])

  const onHandleMouseEnter = () => {
    isMouseOverRef.current = true

    setTimeout(() => {
      if (!isOpenedRef.current && isMouseOverRef.current) {
        setIsMouseOver(true)
      }
    }, INTERVAL_1_SECOND)
  }

  const onHandleMouseLeave = () => {
    isMouseOverRef.current = false
    setIsMouseOver(false)
  }

  return (
    <div className="header--avatars__wrap--wrap">
      {userState.user.is_admin && orderState?.orderViewingUsers?.length > 0 && (
        <div
          className="header--wraps header--avatars__wrap"
          onMouseEnter={onHandleMouseEnter}
          onMouseLeave={onHandleMouseLeave}
        >
          {orderState.orderViewingUsers.length > 1 && (
            <div className="header--avatar header--avatar__counter">
              +
              {' '}
              {orderState.orderViewingUsers.length - 1}
            </div>
          )}
          <div
            className={`header--avatar ${orderState.orderViewingUsers[0]?.is_client ? 'header--avatar__client' : ''}`}
            style={{
              zIndex: 1,
              left: `${AVATAR_STYLE_LEFT}px`,
              backgroundColor: orderState.orderViewingUsers[0]?.color,
            }}
          >
            {orderState.orderViewingUsers[0]?.nickname}
          </div>
          {isMouseOver && (
            <div className="header-users">
              <span className="bold">
                {orderState.orderViewingUsers.slice(0, -1).map((user) => (
                  // eslint-disable-next-line prefer-template
                  user.firstname + ' ' + user.lastname + (user.is_client ? ' (CL)' : '')
                )).join(', ')}
              </span>
              {orderState.orderViewingUsers.length > 1 && (
                <span>
                  {' '}
                  {Translation.and}
                  {' '}
                </span>
              )}
              <span className="bold">
                {orderState.orderViewingUsers.slice(-1).map((user) => (
                  `${user.firstname} ${user.lastname} ${(user.is_client ? '(CL)' : '')}`
                ))}
              </span>
              <span>
                {' '}
                {Translation.also_viewing}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Avatars
